import React from "react";

import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import "sweetalert2/src/sweetalert2.scss";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { QueryProvider } from "./components";
// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <QueryProvider>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                        <Toaster />
                    </NavigationScroll>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
