import React from "react";

// export default function Input(props) {
//     return (
//         <input
//             {...props}
//             className="py-1 px-2 w-full rounded-md bg-gray-100 focus:outline-indigo-500"
//         />
//     );
// }

const Input = ({ className, type, ...props }, ref) => {
    return (
        <input
            {...props}
            ref={ref}
            type={type}
            className={`py-2 ring-1 ring-indigo-200 px-2 w-full rounded-md bg-gray-100 focus:outline-indigo-500 ${className}`}
        />
    );
};
export default React.forwardRef(Input);
