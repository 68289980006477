import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { PhotoSizeSelectLargeOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

function MerchantBackground({ backgroundImage }) {
    const [image, setImage] = useState(null);
    const onDrop = (acceptedFiles) => {
        // Set the uploaded image to state
        setImage(acceptedFiles[0]);
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <section className="col-span-full text-center  mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 ">
            {/* {backgroundImage.url ? (
                <img
                    src={backgroundImage.url}
                    alt="background"
                    className="h-32 w-32 "
                />
            ) : image ? (
                <div className="py-2 flex flex-col justify-center">
                    <h4 className="mb-2">Uploaded Image:</h4>

                    <img
                        src={URL.createObjectURL(image)} // Use createObjectURL to generate a preview URL
                        alt="Uploaded"
                        className="w-52 h-32"
                    />
                    <div className="flex w-full space-x-5 text-center mt-4">
                        <Button
                            variant="contained"
                            className="bg-red-500 text-white"
                            onClick={() => setImage(null)}
                        >
                            Remove
                        </Button>
                        <Button
                            variant="contained"
                            className="bg-indigo-500 text-white"
                            onClick={() => setImage(null)}
                        >
                            Upload
                        </Button>
                    </div>
                </div> */}

            <div {...getRootProps({ className: "py-10 px-6 w-full" })}>
                <PhotoSizeSelectLargeOutlined
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                />
                <p>Drag drop some files here, or click to select files</p>
                <input {...getInputProps()} />
            </div>
        </section>
    );
}

export default MerchantBackground;
