import React from "react";
import { Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { Badge } from "@mui/material";

export default function ImpactStoreCard({ campaign }) {
    // const { data, isPending, isError } = useStoreIds();
    return (
        <Card>
            <CardContent>
                <Link
                    to={`/stores/impactstore/${campaign.CampaignId}`}
                    className=""
                >
                    <h3 className="font-medium">{campaign.AdvertiserName}</h3>
                </Link>
                <div className="">
                    <Badge color="primary">{campaign.ContractStatus}</Badge>

                    <p>{campaign.CampaignDescription}</p>
                    <p>
                        <span className="font-medium">Campaign Url: </span>
                        {campaign.CampaignUrl}{" "}
                    </p>
                </div>
            </CardContent>
        </Card>
    );
}
