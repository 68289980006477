import React from "react";

import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
// import AffiliateTransactions from "../features/transactions/page";
import Applications from "../views/dashboard/Applications";
import { CustomerDetails } from "../views/CustomerDetails";
import ApplicationDetails from "../views/Customer/ApplicationDetails";
import GiftcardMain from "../views/stores/giftcards/giftcardMain";
import CategoriesMain from "../views/stores/categories/categoriesMain";
import { AffiliatePartners } from "../features/affiliate-partners";
import EverflowOffer from "../features/affiliate-partners/everflow/everflow-offer";
import Notification from "../features/notification/Notification";
// dashboard routing
const DashboardDefault = Loadable(
    lazy(() => import("../views/stores/TempDashboard"))
);

// utilities routing
const StoreOrders = Loadable(lazy(() => import("../views/stores/Orders")));
// const StoreSettlements = Loadable(
//     lazy(() => import("../views/stores/Settlements"))
// );

const StoreJuggleApp = Loadable(
    lazy(() => import("../views/stores/JuggleApp"))
);
const UserStats = Loadable(
    lazy(() => import("../features/user-stats/user-stats"))
);
const Feedback = Loadable(lazy(() => import("../features/feedback/feedback")));
const StoreMerchantPortal = Loadable(
    lazy(() => import("../views/stores/MerchantPortal"))
);
const StoreDonations = Loadable(
    lazy(() => import("../views/stores/Donations"))
);
const StoreDiscounts = Loadable(
    lazy(() => import("../views/stores/Discounts"))
);
const DiscountReview = Loadable(
    lazy(() => import("../views/stores/DiscountReview"))
);
const UtilsShadow = Loadable(lazy(() => import("../views/stores/Shadow")));
const RewardHours = Loadable(lazy(() => import("../views/stores/RewardHours")));
const DailyCheckIn = Loadable(
    lazy(() => import("../views/stores/DailyCheckIn"))
);

//affiliate program routes

const Rakuten = Loadable(
    lazy(() => import("../features/affiliate-partners/rakuten/page"))
);
const SliceOfferDetails = Loadable(
    lazy(() =>
        import("../features/affiliate-partners/slice/slice-offer-details")
    )
);
const Partnerize = Loadable(
    lazy(() => import("../features/affiliate-partners/partnerize/page"))
);
const AffiliateTransactions = Loadable(
    lazy(() => import("../features/transactions/page"))
);
const MerchantDetails = Loadable(
    lazy(() => import("../features/affiliate-partners/commission-factory/page"))
);
// sample page routing
const ContactUs = Loadable(lazy(() => import("../views/contact-us")));

const BetaAccess = Loadable(
    lazy(() => import("../views/dashboard/BetaAccess"))
);
const Deals = Loadable(
    lazy(() => import("../views/dashboard/marketing/deals"))
);
const Notifications = Loadable(
    lazy(() => import("../views/dashboard/marketing/notifications"))
);
const ImpactStore = Loadable(
    lazy(() => import("../features/affiliate-partners/impact/page"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <DashboardDefault />,
        },
        {
            path: "",
            children: [
                {
                    path: "default",
                    element: <DashboardDefault />,
                },
                {
                    path: "applications",
                    element: <Applications />,
                },
                {
                    path: "beta-access",
                    element: <BetaAccess />,
                },
            ],
        },
        {
            path: "stores",
            children: [
                {
                    path: "store-orders",
                    element: <StoreOrders />,
                },
                {
                    path: "user-stats",
                    element: <UserStats />,
                },
                {
                    path: "notification",
                    element: <Notification />,
                },
                {
                    path: "feedback",
                    element: <Feedback />,
                },
            ],
        },
        {
            path: "stores",
            children: [
                {
                    path: "store-gift-card",
                    element: <GiftcardMain />,
                },
                {
                    path: "add-categories",
                    element: <CategoriesMain />,
                },
                {
                    path: "affiliate-partners",
                    element: <AffiliatePartners />,
                },
                {
                    path: "impactstore/:id",
                    element: <ImpactStore />,
                },
                {
                    path: "raku/:id",
                    element: <Rakuten />,
                },
                {
                    path: "partnerize/:id",
                    element: <Partnerize />,
                },
                {
                    path: "commission-factory/:id",
                    element: <MerchantDetails />,
                },
                {
                    path: "everflow/:id",
                    element: <EverflowOffer />,
                },
                {
                    path: "slice/:id",
                    element: <SliceOfferDetails />,
                },
            ],
        },
        {
            path: "stores",
            children: [
                {
                    path: "transactions",
                    element: <AffiliateTransactions />,
                },
            ],
        },
        {
            path: "stores",
            children: [
                {
                    path: "juggle-app",
                    element: <StoreJuggleApp />,
                },
            ],
        },
        {
            path: "stores",
            children: [
                {
                    path: "merchant-portal",
                    element: <StoreMerchantPortal />,
                },
            ],
        },
        {
            path: "stores",
            children: [
                {
                    path: "donations",
                    element: <StoreDonations />,
                },
            ],
        },
        {
            path: "stores",
            children: [
                {
                    path: "discounts",
                    element: <StoreDiscounts />,
                },
                {
                    path: "discounts/:id",
                    element: <DiscountReview />,
                },
            ],
        },

        {
            path: "utils",
            children: [
                {
                    path: "util-shadow",
                    element: <UtilsShadow />,
                },
            ],
        },
        {
            path: "marketing",
            children: [
                {
                    path: "daily-checkin",
                    element: <DailyCheckIn />,
                },
            ],
        },
        {
            path: "marketing",
            children: [
                {
                    path: "reward-hours",
                    element: <RewardHours />,
                },
            ],
        },
        {
            path: "marketing",
            children: [
                {
                    path: "deals",
                    element: <Deals />,
                },
            ],
        },
        {
            path: "marketing",
            children: [
                {
                    path: "notifications",
                    element: <Notifications />,
                },
            ],
        },
        {
            path: "contact-us",
            element: <ContactUs />,
        },
        {
            path: "merchant/:id",

            element: <CustomerDetails />,
        },
        {
            path: "applicationdetails/:id",
            element: <ApplicationDetails />,
        },
    ],
};

export default MainRoutes;
