import axiosInstance from "../../../../config/Helpers";

export default async function patchMerchant(id, body) {
    try {
        const response = await axiosInstance.patch(
            `affiliate-program/campaign?source=commission-factory&id=${id}`,
            body
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
