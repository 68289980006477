import {
    Button,
    TextField
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useCategoryMutation from "../hooks/useCategoryMutation";
import { useForm } from "react-hook-form";

const UpsertCategoryModal = ({ id, category }) => {
    const queryClient = useQueryClient();
    const mutation = useCategoryMutation();
    const { control, register, handleSubmit } = useForm({
        defaultValues: {
            ...category
        }
    });
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false) }

    return (
        <>
            <Button
                variant="outlined"
                onClick={() => setOpen(true)}
            >
                {id ? 'Edit' : 'Add'}
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(async (data) => {
                        const res = await mutation.mutateAsync({ id, ...data });
                        await queryClient.refetchQueries({ queryKey: ['categories'] });
                        handleClose();
                    }),
                }}
            >
                <DialogTitle> {id ? 'Edit' : 'Add'} Category</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        {...register(`name`)}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="icon"
                        name="icon"
                        label="Icon url"
                        type="text"
                        fullWidth
                        variant="outlined"
                        {...register(`icon`)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit"> {id ? 'Save' : 'Add'}</Button>
                </DialogActions>
            </Dialog >

        </>
    )
}
export default UpsertCategoryModal