import axiosInstance from "../../../config/Helpers";
import { useQuery } from "@tanstack/react-query";

const fetchAllCount = async () => {
    const { data } = await axiosInstance.get("affiliate-program/all-count/");
    return { data };
};

export default function useALlCount() {
    return useQuery({
        queryKey: ["all-count"],
        queryFn: fetchAllCount,
    });
}
