import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../config/Helpers";

export default function useGetFormDb(id) {
    const { data, isPending, error } = useQuery({
        queryKey: ["commission-factory", id],
        queryFn: () => {
            return axiosInstance
                .get(
                    `affiliate-program/campaign/${id}?source=commission-factory`
                )
                .then((res) => res.data);
        },
    });
    return { data, isPending, error };
}
