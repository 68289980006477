import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

function ConfirmationDialog({ handleDeleteMerchant }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div className="text-red-500 text-center mt-4">
                <Button
                    onClick={handleClickOpen}
                    variant="outlined"
                    color="error"
                >
                    Delete merchant from system
                </Button>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle className="text-xl text-center">
                    Are you sure?
                </DialogTitle>

                <DialogContent sx={{ width: 250, textAlign: "center" }}>
                    <Button
                        sx={{ marginRight: 6 }}
                        className="mr-4"
                        variant="outlined"
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={handleDeleteMerchant}
                        variant="outlined"
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ConfirmationDialog;
