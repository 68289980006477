import { CardMedia } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Button } from "@mui/material";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../../config/Helpers";
// import CategoriesModel from "./categoriesModel";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GiftCardEditDialog from "./GiftCardEditDialog";

// const GiftCard = styled(Card)({
//     maxWidth: 400,
//     background: "none",
//     margin: (theme) => theme.spacing(2),
// });

const GiftCardMedia = styled(CardMedia)({
    height: 150,
    width: 150,
    objectFit: "contain",
    backgroundColor: "none",
});

const getGiftCards = async (activeTab) => {
    const res = await axiosInstance.get(`giftcard/get-gift-cards/${activeTab}`);
    if (activeTab === "giftpay") {
        return { result: res.data.Cards };
    } else {
        return { result: res.data.results };
    }
};

const saveInDb = async ({ activeTab, payload }) => {
    const res = await axiosInstance.post(
        `giftcard/save-in-db/${activeTab}`,
        payload
    );
    return res.data;
};

export default function GiftPayCard({ activeTab }) {
    const queryClient = useQueryClient();

    const { data: giftCards, isLoading } = useQuery({
        queryKey: ["get-gift-cards", activeTab],
        queryFn: () => getGiftCards(activeTab),
    });

    const mutation = useMutation({
        mutationFn: saveInDb,
        onSuccess: () => {
            queryClient.invalidateQueries(["get-gift-cards", activeTab]);
        },
    });

    const handleSave = (payload) => {
        mutation.mutate({ activeTab, payload });
    };

    if (isLoading) return <CircularProgress />;

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Card</TableCell>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">Act.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {giftCards.result.map((row) => (
                        <TableRow
                            key={row.ID}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                <GiftCardMedia
                                    component="img"
                                    image={
                                        row.ImageURL
                                            ? row.ImageURL
                                            : row.card_logo
                                    }
                                    alt="Gift Card Image"
                                />
                            </TableCell>
                            <TableCell>
                                {row.Title
                                    ? row.Title
                                    : row.name +
                                      " gift card amount $" +
                                      row.denominations[0].amount * 0.01}
                            </TableCell>
                            <TableCell>
                                {Object.keys(row).includes("_id") ? (
                                    <GiftCardEditDialog data={row} />
                                ) : (
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => handleSave(row)}
                                    >
                                        Save Gift Card
                                    </Button>
                                )}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        // <Grid container spacing={5} sx={{ marginTop: 1 }}>
        //     {giftCards &&
        //         giftCards.result.map((giftCard, index) => (
        //             <Grid
        //                 item
        //                 key={index}
        //                 xs={12}
        //                 sm={12}
        //                 sx={{
        //                     display: "flex",
        //                     justifyContent: "start",
        //                     alignItems: "center",
        //                     gap: 5,
        //                 }}
        //             >
        //                 <GiftCard>
        //                     <Typography variant="h3">
        //                         {giftCard.Title
        //                             ? giftCard.Title
        //                             : giftCard.name +
        //                             " gift card amount $" +
        //                             giftCard.denominations[0].amount * 0.01}
        //                     </Typography>
        // <GiftCardMedia
        //     component="img"
        //     image={
        //         giftCard.ImageURL
        //             ? giftCard.ImageURL
        //             : giftCard.card_logo
        //     }
        //     alt="Gift Card Image"
        // />
        //                 </GiftCard>
        //                 <CategoriesModel
        //                     giftCard={giftCard.category}
        //                     handleSave={handleSave}
        //                 />
        //                 <div className="flex items-center">
        //                     <input
        //                         type="number"
        //                         className="py-2 px-2 mr-2 border-2 rounded-md"
        //                     />
        //                     <Typography>Months</Typography>
        //                 </div>
        // {Object.keys(giftCard).includes("_id") ? (
        //     <Typography variant="h4">
        //         Linked to Mobile App
        //     </Typography>
        // ) : (
        //     <Button
        //         variant="contained"
        //         color="primary"
        //         onClick={() => handleSave(giftCard)}
        //     >
        //         Save Gift Card
        //     </Button>
        // )}
        //             </Grid>
        //         ))}
        // </Grid>
    );
}
