import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CheckboxComponent from "./checkboxes";

export default function CategoriesModel({ giftCard, handleSave }) {
    const [open, setOpen] = React.useState(false);
    const [categoriesAdded, setAddedCategories] = React.useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveCategories = () => {
        // to be continue
        handleSave({ ...giftCard, category: categoriesAdded });
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                Select Categories
            </Button>
            <Dialog
                // maxWidth='lg'
                fullWidth={true}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle sx={{ fontSize: 20 }}>
                    Select Categories
                </DialogTitle>
                <DialogContent>
                    <CheckboxComponent
                        setAddedCategories={setAddedCategories}
                        giftCard={giftCard}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={handleSaveCategories} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
