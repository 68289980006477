import React from "react";
import { useParams } from "react-router";
import useStoreStatus from "../api/useStoreStatus";
import { Loading } from "../../../components/ui";
import AddToDdView from "./add-to-database";
import UpdateOfferView from "./update-offer-view";

export default function SliceOfferDetails() {
    const { id } = useParams();
    const { isPending, isError, data } = useStoreStatus(`slice-${id}`);
    if (isPending) return <Loading />;
    if (isError) return <div>Error</div>;
    if (!data.success) return <AddToDdView />;
    return (
        <div>
            <UpdateOfferView data={data.data[0]} />
        </div>
    );
}
