import axiosInstance from "../../../config/Helpers";
import { useQuery } from "@tanstack/react-query";

export const getCampaigns = async () => {
    const response = await axiosInstance.get("partnerize/campaigns");
    return response.data;
};

export default function useGetPartnerizeCampaigns() {
    return useQuery({
        queryKey: ["partnerizeCampaigns"],
        queryFn: getCampaigns,
    });
}
