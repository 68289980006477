import React from "react";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Alert from "@mui/icons-material/AddAlert";
import City from "@mui/icons-material/LocationCityRounded";
import { Avatar } from "@mui/material";
import { iconStyle } from "../../views/CustomerDetails";
export const UserCard = ({ name, phone, email, postcode, city, street }) => {
  return (
    <div className="flex flex-row  ">
      <div className="pr-3 align-top items-start">
        <Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
          sx={{ width: 43, height: 43 }}
        />
      </div>
      <div className="flex flex-col justify-center align-middle items-start gap-1">
        <p className="font-semibold text-base "> {name}</p>
        <div className="flex flex-row justify-center align-middle items-center gap-1">
          <LocalPhoneOutlinedIcon style={iconStyle} />
          <p>{phone}</p>
        </div>
        <div className="flex flex-row justify-between align-middle items-center gap-1 ">
          <EmailOutlinedIcon style={iconStyle} />
          <p>{email}</p>
        </div>
        <div className="flex flex-row justify-between align-middle items-center gap-1 ">
          <Alert style={iconStyle} />
          <p>{postcode}</p>
        </div>
        <div className="flex flex-row justify-between align-middle items-center gap-1 ">
          <City style={iconStyle} />
          <p>
            {city},{street}
          </p>
        </div>
      </div>
    </div>
  );
};
