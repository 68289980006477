import React from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import success from "../../assets/images/sucess.svg";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useEffect } from "react";
import axiosInstance from "../../config/Helpers";
import { useParams } from "react-router";
import { useState } from "react";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
export const MerchantStatus = () => {
    const { id } = useParams();

    const [formData, setFormData] = useState({
        attachedToAPI: false,
        registered: false,
        paymentStatus: false,
    });
    useEffect(() => {
        const getData = async () => {
            const res = await axiosInstance.get(
                `/merchant-form-data/approve/${id}`
            );
            const data = res.data;
            console.log(data.attachedToAPI);
            setFormData({
                attachedToAPI: data.attachedToAPI || false,
                registered: data.registered || false,
                paymentStatus: data.paymentStatus || false,
            });
        };
        getData();
    }, []);
    return (
        <div className=" flex justify-center mt-9">
            <div className="bg-gray-200 p-8 rounded-lg shadow-lg text-center">
                <h1 className="text-2xl font-semibold text-sky-500">
                    Registration Status
                </h1>
                <p>Pending to complete registration for the Merchant Portal</p>
                {formData.registered ? (
                    <>
                        <VerifiedIcon
                            style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "10px",
                                color: "green",
                            }}
                        />
                        <h2 className="text-2xl font-semibold text-green-700">
                            Complete
                        </h2>
                    </>
                ) : (
                    <>
                        <AutorenewIcon
                            style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "10px",
                            }}
                        />
                        <h2 className="text-2xl font-semibold text-red-500">
                            Pending
                        </h2>
                    </>
                )}
            </div>
        </div>
    );
};
export const ApiStatus = (props) => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        attachedToAPI: false,
        registered: false,
        paymentStatus: false,
    });
    useEffect(() => {
        const getData = async () => {
            const res = await axiosInstance.get(
                `/merchant-form-data/approve/${id}`
            );
            const data = res.data;
            setFormData({
                attachedToAPI: data.attachedToAPI || false,
                registered: data.registered || false,
                paymentStatus: data.paymentStatus || false,
            });
        };
        getData();
    }, []);
    return (
        <div className=" flex justify-center mt-9">
            <div className="bg-gray-200 p-8 rounded-lg shadow-lg text-center">
                <h1 className="text-2xl font-semibold text-sky-500">
                    Api Status
                </h1>
                <p>Pending for merchants to connect api</p>
                {formData.attachedToAPI ? (
                    <>
                        <VerifiedIcon
                            style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "10px",
                                color: "green",
                            }}
                        />
                        <h2 className="text-2xl font-semibold text-green-700">
                            Complete
                        </h2>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    background: "green",
                                    borderRadius: "50%",
                                    width: "15px",
                                    height: "15px",
                                    margin: "10px",
                                }}
                            ></div>
                            <h2>online</h2>
                        </div>
                    </>
                ) : (
                    <>
                        <AutorenewIcon
                            style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "10px",
                            }}
                        />
                        <h2 className="text-2xl font-semibold text-red-500">
                            Pending
                        </h2>
                    </>
                )}
            </div>
        </div>
    );
};
export const PaymentStatus = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        attachedToAPI: false,
        registered: false,
        paymentStatus: false,
    });
    useEffect(() => {
        const getData = async () => {
            const res = await axiosInstance.get(
                `/merchant-form-data/approve/${id}`
            );
            const data = res.data;
            setFormData({
                attachedToAPI: data.attachedToAPI || false,
                registered: data.registered || false,
                paymentStatus: data.paymentStatus || false,
            });
        };
        getData();
    }, []);
    return (
        <div className=" flex justify-center mt-9">
            <div className="bg-gray-200 p-8 rounded-lg shadow-lg text-center">
                <h1 className="text-2xl font-semibold text-sky-500">
                    Payment Status
                </h1>
                {formData.paymentStatus ? (
                    <>
                        <VerifiedIcon
                            style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "10px",
                                color: "green",
                            }}
                        />
                        <h2 className="text-2xl font-semibold text-green-700">
                            Complete
                        </h2>
                    </>
                ) : (
                    <>
                        <AutorenewIcon
                            style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "10px",
                            }}
                        />
                        <h2 className="text-2xl font-semibold text-red-500">
                            Pending
                        </h2>
                    </>
                )}
            </div>
        </div>
    );
};
export const SuccessListing = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        attachedToAPI: false,
        registered: false,
        paymentStatus: false,
    });
    const buttonHandler = async () => {
        try {
            await axiosInstance.patch(`/list-merchant/${id}`);
            toast("Merchant Listed", {
                position: "top-right ",
            });
            getData();
        } catch (error) {
            console.log(error);
            toast(error?.response?.data, {
                position: "top-right",
            });
        }
    };
    const getData = async () => {
        const res = await axiosInstance.get(
            `/merchant-form-data/approve/${id}`
        );
        const data = res.data;
        setFormData({
            isListed: data.isListed || false,
            registered: data.registered || false,
            attachedToAPI: data.attachedToAPI || false,
            paymentStatus: data.paymentStatus || false,
        });
    };
    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            {!formData?.isListed ? (
                <div className=" flex flex-col justify-center mt-9 items-center">
                    <div className="bg-gray-200 p-8 rounded-lg shadow-lg text-center">
                        <p className="text-lg  text-300 pb-2">
                            List merchant on app
                        </p>

                        <img alt="success" src={success} width={"200px"} />
                    </div>
                    <Button
                        disabled={
                            !formData?.attachedToAPI || !formData?.paymentStatus
                        }
                        sx={{
                            backgroundColor: "green",
                            marginTop: "10px",
                            width: "50%",
                            margin: "10px auto auto auto",
                        }}
                        onClick={buttonHandler}
                        variant="outlined"
                        color="success"
                    >
                        List on App
                    </Button>
                </div>
            ) : (
                <>
                    <div className="bg-gray-200 p-8 rounded-lg shadow-lg text-center">
                        <VerifiedIcon
                            style={{
                                width: "50px",
                                height: "50px",
                                marginTop: "10px",
                                color: "green",
                            }}
                        />
                        <h2 className="text-2xl font-semibold text-green-700">
                            Listed Successfully
                        </h2>
                    </div>
                </>
            )}
        </>
    );
};
