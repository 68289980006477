// assets
import { IconKey, IconApps, IconAd2, IconAccessPoint } from "@tabler/icons";

// constant
const icons = {
  IconKey,
  IconApps,
  IconAd2,
  IconAccessPoint,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: "gate",
  title: "Gate",
  caption: "Gate Keeper",
  type: "group",
  children: [
    {
      id: "application",
      title: "Applications",
      type: "item",
      url: "/applications",
      icon: icons.IconApps,
      breadcrumbs: false,
    },
    {
      id: "marketing",
      title: "Marketing",
      type: "collapse",
      icon: icons.IconAd2,
      breadcrumbs: false,
      children: [
        {
          id: "deals",
          title: "Deals",
          type: "item",
          url: "/marketing/deals",
          target: true,
        },
        {
          id: "notifications",
          title: "Push Notifications",
          type: "item",
          url: "/marketing/notifications",
          target: true,
        },
      ],
    },
    {
      id: "beta-access",
      title: "Beta Access",
      type: "item",
      url: "/beta-access",
      icon: icons.IconAccessPoint,
      breadcrumbs: false,
    },
  ],
};

export default pages;
