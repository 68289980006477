import { PhotoSizeSelectLargeOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import Select from "react-select";
import toast from "react-hot-toast";

import {
    UploadLogo,
    MerchantBackground,
} from "../../features/merchant/components";
import {
    useGetMerchantInfo,
    useUpdateMerchant,
} from "../../features/merchant/api";
import { storeCategoriesOptions, storeTypeOptions } from "./constant";
import CategoriesModel from "../stores/giftcards/categoriesModel";
const MerchantListing = () => {
    const { id } = useParams();
    const merchant = useGetMerchantInfo(id);
    const updateMerchant = useUpdateMerchant(id);
    const form = useForm();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = form;

    const handleUpdateMerchant = async (fData) => {
        updateMerchant.mutate(fData);
    };
    useEffect(() => {
        if (updateMerchant.isSuccess) {
            toast.success("Merchant updated successfully");
        }
    }, [updateMerchant.isSuccess]);
    if (merchant.isPending) return <div>Loading...</div>;
    return (
        <form onSubmit={handleSubmit(handleUpdateMerchant)}>
            <div className="space-y-10">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Merchant Information
                    </h2>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Merchant Name
                            </label>
                            <div className="mt-2">
                                <input
                                    required
                                    type="text"
                                    defaultValue={merchant.data.business_name}
                                    {...register("bussiness_name")}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        {/* Select type of store start */}
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="first-name"
                                className="block text-sm  font-medium  leading-6 text-gray-900"
                            >
                                Store type
                            </label>
                            <Select
                                className="mt-2"
                                defaultInputValue={merchant.data.store_type}
                                {...register("store_type")}
                                options={storeTypeOptions}
                            />
                        </div>
                        {/* Select type of store end */}
                        {/* Select Store category start */}
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium  leading-6 text-gray-900"
                            >
                                Select category For merchant
                            </label>
                            {/* <Select options={storeCategoriesOptions} /> */}
                            {merchant && (
                                <CategoriesModel
                                    giftCard={merchant.data.categories}
                                    handleSave={(selectedCategories) => {
                                        setValue(
                                            "categories",
                                            selectedCategories &&
                                                selectedCategories.category
                                        );
                                    }}
                                />
                            )}
                        </div>
                        {/* Select Store category end */}
                        <div className="col-span-full">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Url
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    defaultValue={merchant.data.url}
                                    {...form.register("url")}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="col-span-full">
                            <label
                                htmlFor="about"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Description
                            </label>
                            <div className="mt-2">
                                <textarea
                                    rows={3}
                                    {...form.register("description")}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={merchant.data.description}
                                />
                            </div>
                            <p className="mt-3 text-sm leading-6 text-gray-600">
                                Write a few sentences about Merchant.
                            </p>
                        </div>
                        <div className="col-span-full">
                            <label
                                htmlFor="about"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                terms and conditions
                            </label>
                            <div className="mt-2">
                                <textarea
                                    rows={3}
                                    {...form.register("terms_conditions")}
                                    className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={
                                        merchant.data.terms_conditions
                                    }
                                />
                            </div>
                            <p className="mt-3 text-sm leading-6 text-gray-600">
                                Add terms and conditions
                            </p>
                        </div>
                        {/* Base commistion rate */}
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Base Commission
                            </label>
                            <div className="mt-2">
                                <div className="flex">
                                    <input
                                        defaultValue={
                                            merchant.data.baseCommission
                                        }
                                        type="number"
                                        {...form.register("baseCommission")}
                                        className="block w-full rounded-md border-0 p-5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />

                                    <PercentIcon
                                        style={{ margin: "2px 0 0 10px" }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Base Reward Rate */}
                        <div className="sm:col-span-4">
                            <label
                                htmlFor="last-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Base Reward Rate
                            </label>
                            <div className="mt-2">
                                <div className="flex">
                                    <input
                                        type="number"
                                        defaultValue={merchant.data.reward_rate}
                                        {...form.register("reward_rate", {
                                            required: true,
                                        })}
                                        id="basereawrdrate"
                                        className="block w-full rounded-md border-0 p-5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    <PercentIcon
                                        style={{ margin: "2px 0 0 10px" }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Upload background image  */}
                        <MerchantBackground
                            backgroundImage={merchant.data?.backgroundImage}
                        />
                        {/* <div className="col-span-full  mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div className="text-center">
                                <PhotoSizeSelectLargeOutlined
                                    className="mx-auto h-12 w-12 text-gray-300"
                                    aria-hidden="true"
                                />
                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>Upload a background image</span>
                                        <input
                                            id="file-upload"
                                            name="backgroundImage"
                                            type="file"
                                            style={{
                                                border: "none",
                                            }}
                                            onChange={
                                                handleBackgroundImageChange
                                            }
                                        />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                {imagePreviewBg && (
                                    <img
                                        src={imagePreviewBg}
                                        alt="Preview"
                                        className="mt-2 w-32 h-32 m-auto"
                                    />
                                )}
                                <p className="text-xs leading-5 text-gray-600">
                                    PNG, JPG, GIF up to 10MB
                                </p>
                            </div>
                        </div> */}
                        {/* Upload logo component */}
                        <UploadLogo logo={merchant.data?.logo} />
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="submit"
                    className="rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </form>
    );
};

export default MerchantListing;
