import React from "react";
import { useForm, Controller } from "react-hook-form";
import CategorySelect from "../../../components/ui/CategorySelect";
import useCategories from "../../../views/stores/categories/hooks/useCategoriesQuery";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { updateOffer } from "../api";
export default function UpdateView({ data }) {
    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            name: data[0].name,
            tracking_url: data[0].tracking_url,
            commission_rate: data[0].commission_rate,
            points: data[0].points,
            categories: data[0].categories,
            status: data[0].status,
        },
    });
    const { data: categories } = useCategories();
    return (
        <div className="bg-white p-5 rounded-md">
            <form className="max-w-screen-md mx-auto space-y-5">
                <div className="flex">
                    <h1 className="w-1/3">Title:</h1>
                    <input
                        {...register("title")}
                        placeholder="Enter your title"
                        className="px-3 py-1 w-2/3 h-8 rounded-md bg-gray-100"
                    />
                </div>

                <div className="flex">
                    <h1 className="w-1/3" htmlFor="reawad_rate">
                        Commission rate
                    </h1>
                    <input
                        {...register("commission_rate")}
                        className=" w-2/3  bg-gray-100 rounded-md px-3 h-8"
                        placeholder="Enter commission rate"
                    />
                </div>
                <div className="flex">
                    <h1 className="w-1/3" htmlFor="reawad_rate">
                        Points
                    </h1>
                    <input
                        {...register("points")}
                        className="w-2/3  bg-gray-100 rounded-md px-3 h-8"
                        placeholder="Enter points"
                    />
                </div>
                <div className="flex">
                    <h1 className="w-1/3" htmlFor="reawad_rate">
                        Tracking URL
                    </h1>
                    <input
                        {...register("tracking_url")}
                        className="w-2/3  bg-gray-100 rounded-md px-3 h-8"
                        placeholder="Enter tracking Url"
                    />
                </div>
                <div className="flex  items-center">
                    <h1 className="w-1/3">Category</h1>
                    <Controller
                        name="categories"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                            <CategorySelect options={categories} {...rest} />
                        )}
                    />
                </div>
                <div className="flex">
                    <h1 className="w-1/3">Description</h1>
                    <textarea
                        {...register("description")}
                        placeholder="Enter your description"
                        className="w-2/3 h-32 bg-gray-100 p-2 rounded-md"
                    />
                </div>
                <div className="flex">
                    <h1 className="w-1/3">Terms & Conditions</h1>
                    <textarea
                        {...register("terms")}
                        placeholder="Enter your terms and conditions"
                        className="w-2/3 border border-gray-200 p-2 rounded-md"
                    />
                </div>
                <Button
                    // onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    className="bg-indigo-500"
                >
                    Update offer info
                </Button>
            </form>
            <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
    );
}
