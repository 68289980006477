import React from "react";
import { Card, CardContent } from "@mui/material";

import { Link } from "react-router-dom";

export default function RukuMerchantList({ useRaku }) {
    const { data, isPending, isError } = useRaku;

    if (isPending) return <div>Loading...</div>;
    if (isError) return <div>Something went wrong</div>;

    return (
        <div className="p-3">
            <div className="grid grid-cols-3 gap-5">
                {data.data.data["ns1:getMerchByAppStatusResponse"][
                    "ns1:return"
                ].map((d, i) => {
                    d = {
                        ...d,
                        name: d["ns1:name"][0],
                        offerName: d["ns1:offer"][0]["ns1:offerName"],
                        offerId: d["ns1:offer"][0]["ns1:offerId"],
                        commisionTerms:
                            d["ns1:offer"][0]["ns1:commissionTerms"],
                        id: d["ns1:name"][0].replaceAll(" ", "_"),
                    };

                    d.exists = !!data.allData.data.find((_d) => {
                        return _d.store_id === d["ns1:mid"][0];
                    });

                    return (
                        <Card key={i}>
                            <CardContent>
                                <Link
                                    to={`/stores/raku/${d["ns1:mid"][0]}`}
                                    state={d}
                                >
                                    <p className="font-bold text-lg">
                                        {d.name}
                                    </p>
                                </Link>
                                {/* <p>
                                        offerId:
                                        {d.offerId}
                                    </p> */}
                                <p>
                                    <strong>Offer Name:</strong>
                                    {d.offerName}
                                </p>
                                <p>
                                    <strong>Commission Terms:</strong>
                                    {d.commisionTerms}
                                </p>
                                {/* <p>
                                        alsoName:
                                        {d["ns1:offer"][0]["ns1:alsoName"]}
                                    </p> */}
                                <h1>
                                    {d.exists
                                        ? "Saved in database"
                                        : "Not Saved in database"}
                                </h1>
                            </CardContent>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
}
