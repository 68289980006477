import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axiosInstance from "../../../config/Helpers";
import { useQuery } from "@tanstack/react-query";

const getCategories = async () => {
    const res = await axiosInstance.get("getCategories");
    return res.data;
};

export default function CheckboxComponent({ setAddedCategories, giftCard }) {
    const { data: categories } = useQuery({
        queryKey: ["getCategories"],
        queryFn: getCategories,
    });

    const [checkedCategories, setCheckedCategories] = useState({});

    // main logic for maintaining the state
    // {parent: {
    // isParentChecked: true or false
    // rest of the child component
    // child1: true or false
    // child2: true false
    // }
    // }

    const filterOutAddedCategories = useCallback(
        (categoriesState) => {
            const currentCategories = {};
            if (categoriesState) {
                Object.keys(categoriesState).forEach((categoryKey) => {
                    currentCategories[categoryKey] = [];
                    Object.keys(categoriesState[categoryKey]).forEach(
                        (item) => {
                            if (
                                categoriesState[categoryKey][item] &&
                                item !== "isParentChecked"
                            ) {
                                currentCategories[categoryKey] = [
                                    item,
                                    ...currentCategories[categoryKey],
                                ];
                            }
                        }
                    );
                    if (currentCategories[categoryKey].length === 0) {
                        delete currentCategories[categoryKey];
                    }
                });
                setAddedCategories(currentCategories);
            }
        },
        [setAddedCategories]
    );

    const handleCategoryCheckboxChangeParent = (event, category) => {
        setCheckedCategories((prevCheckedCategories) => {
            const newCheckedCategories = { ...prevCheckedCategories };
            const subCategories = {};
            categories[category].values?.map((item) => {
                subCategories[item] = event.target.checked;
                return null;
            });
            newCheckedCategories[category] = {
                isParentChecked: event.target.checked,
                ...subCategories,
            };
            filterOutAddedCategories(newCheckedCategories);
            return newCheckedCategories;
        });
    };
    const handleValueCheckboxChangeChild = useCallback(
        (event, category, value) => {
            setCheckedCategories((prevCheckedCategories) => {
                const newCheckedCategories = { ...prevCheckedCategories };
                newCheckedCategories[category] = {
                    ...newCheckedCategories[category],
                    [value]: event.target.checked,
                };
                filterOutAddedCategories(newCheckedCategories);
                return newCheckedCategories;
            });
        },
        [filterOutAddedCategories]
    );

    useEffect(() => {
        if (giftCard) {
            Object.keys(giftCard).forEach((categoryKey) => {
                giftCard[categoryKey].forEach((category) => {
                    handleValueCheckboxChangeChild(
                        { target: { checked: true } },
                        categoryKey,
                        category
                    );
                });
            });
        }
    }, [giftCard, handleValueCheckboxChangeChild]);

    return (
        categories &&
        Object.keys(categories).map((category, index) => {
            return category !== "_id" ? (
                <div key={index}>
                    <FormControlLabel
                        label={category}
                        control={
                            <Checkbox
                                checked={
                                    checkedCategories[category]
                                        ?.isParentChecked || false
                                }
                                onChange={(e) =>
                                    handleCategoryCheckboxChangeParent(
                                        e,
                                        category
                                    )
                                }
                            />
                        }
                    />
                    {categories[category].values?.map((item, itemIndex) => (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3,
                            }}
                            key={itemIndex}
                        >
                            <FormControlLabel
                                label={item}
                                control={
                                    <Checkbox
                                        checked={
                                            checkedCategories[category]?.[
                                                item
                                            ] || false
                                        }
                                        onChange={(e) =>
                                            handleValueCheckboxChangeChild(
                                                e,
                                                category,
                                                item
                                            )
                                        }
                                    />
                                }
                            />
                        </Box>
                    ))}
                </div>
            ) : null;
        })
    );
}
