import DeleteIcon from '@mui/icons-material/Delete';
import {
    Button,
    IconButton,
    TextField,
    Badge
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import useCategoryMutation from "../hooks/useCategoryMutation";

const UpsertSubCategoryModal = ({ id, data }) => {
    const queryClient = useQueryClient();
    const mutation = useCategoryMutation();
    const { control, register, handleSubmit } = useForm({
        defaultValues: {
            categories: data
        }
    });
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "categories", // unique name for your Field Array
    });

    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false) }

    return (
        <>
            <Badge badgeContent={data.length} color="primary">
                <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                >
                    Sub categories
                </Button>
            </Badge>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(async (data) => {
                        const res = await mutation.mutateAsync({
                            id,
                            subCategories: data.categories
                        });
                        await queryClient.refetchQueries({ queryKey: ['categories'] });
                        handleClose();
                    }),
                }}
            >
                <DialogTitle>Edit Category</DialogTitle>
                <DialogContent>
                    {fields.map((item, index) => (
                        <div className="flex flex-row justify-between items-center" key={item.id}>
                            <TextField
                                required
                                margin="dense"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                {...register(`categories.${index}.name`)}
                            />
                            <IconButton type="button" aria-label="delete" onClick={() => remove(index)}><DeleteIcon /></IconButton>
                        </div>
                    ))}
                    <Divider style={{ marginBottom: 10 }} />
                    <Button type="button" variant="outlined"
                        onClick={() => append({ name: "", })}>Add Field</Button>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </Dialog >

        </>
    )
}
export default UpsertSubCategoryModal