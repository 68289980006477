import dashboard from "./dashboard";
import pages from "./pages";
import store from "./store";
import other from "./other";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, pages, store, other],
};

export default menuItems;
