import React from "react";
import { useParams } from "react-router";

import UpdateView from "./update-view";
import useDb from "./api/use-db";
import OfferDetails from "./offer-details";

export default function EverflowOffer() {
    const { id } = useParams();

    const { data, isPending, isError } = useDb(id);

    if (isPending) return <div>Loading...</div>;
    if (isError) return <div>Error</div>;
    if (data.length) return <UpdateView data={data} />;

    return (
        <div className="container">
            <OfferDetails />
        </div>
    );
}
