import React from "react";
import { Card, CardContent, CardHeader, Avatar } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { Loading } from "../../../../components/ui";
import { useGetAllMerchant } from "../api";

export default function Tabview({ data, isPending, isError }) {
    const comfactMerchant = useGetAllMerchant();
    const [storeIds, setStoreIds] = React.useState([]);
    React.useEffect(() => {
        if (!comfactMerchant.isPending) {
            setStoreIds(comfactMerchant.data.map((item) => item.store_id));
        }
    }, [comfactMerchant.isPending]);
    if (isPending) return <Loading />;
    if (isError) return <div>Something went wrong </div>;
    return (
        <div className="py-2">
            <Grid container spacing={3}>
                {data.map((item) => {
                    return (
                        <Grid key={item.Id}>
                            <Link
                                key={item.Id}
                                to={`/stores/commission-factory/${item.Id}`}
                            >
                                <Card key={item.Id}>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                src={item.AvatarUrl}
                                            ></Avatar>
                                        }
                                        title={item.Name}
                                    />

                                    <CardContent>
                                        <div className="flex flex-col space-y-2">
                                            <h1>
                                                Commission Rate:{" "}
                                                {item.CommissionRate}
                                            </h1>
                                            <h1>
                                                Target Market:{" "}
                                                {item.TargetMarket}
                                            </h1>
                                            <h1>
                                                Target Url: {item.TargetUrl}
                                            </h1>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
}
