// assets
import {
    IconShoppingCart,
    IconFileDescription,
    IconPalette,
    IconShadow,
    IconSpeakerphone,
    IconCalendarEvent,
    IconAlarm,
    IconCircles,
    IconAffiliate,
    IconDiscount2,
} from "@tabler/icons";
import { AiOutlineUser } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";
// constant
const icons = {
    IconShoppingCart,
    IconFileDescription,
    IconPalette,
    IconShadow,
    IconSpeakerphone,
    IconCalendarEvent,
    IconAlarm,
    IconCircles,
    IconAffiliate,
    IconDiscount2,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const store = {
    id: "store",
    title: "Juggle",
    type: "group",
    children: [
        {
            id: "store-orders",
            title: "Customers",
            type: "item",
            url: "/stores/store-orders",
            icon: icons.IconShoppingCart,
            breadcrumbs: false,
        },
        {
        id: "store-orders",
            title: "Notification",
            type: "item",
            url: "/stores/notification",
            icon: icons.IconAlarm,
            breadcrumbs: false,
        },
        {
            id: "user-stats",
            title: "User Stats",
            type: "item",
            url: "/stores/user-stats",
            icon: AiOutlineUser,
            breadcrumbs: false,
        },
        {
            id: "store-settlements",
            title: "Transactions",
            type: "item",
            url: "/stores/transactions",
            icon: icons.IconFileDescription,
            breadcrumbs: false,
        },
        {
            id: "store-juggle-app",
            title: "Juggle App",
            type: "item",
            url: "/stores/juggle-app",
            icon: icons.IconCircles,
            breadcrumbs: false,
        },
        {
            id: "store-merchant-portal",
            title: "Merchant portal",
            type: "item",
            url: "/stores/merchant-portal",
            icon: icons.IconSpeakerphone,
            breadcrumbs: false,
        },
        {
            id: "affiliate-partners",
            title: "Affiliate Partners",
            type: "item",
            url: "/stores/affiliate-partners",
            icon: icons.IconAffiliate,
            breadcrumbs: false,
        },
        {
            id: "store-gift-card",
            title: "Gift Cards",
            type: "item",
            url: "/stores/store-gift-card",
            icon: icons.IconSpeakerphone,
            breadcrumbs: false,
        },
        {
            id: "add-categories",
            title: "Categories",
            type: "item",
            url: "/stores/add-categories",
            icon: icons.IconSpeakerphone,
            breadcrumbs: false,
        },
        {
            id: "store-donations",
            title: "Donations",
            type: "item",
            url: "/stores/donations",
            icon: icons.IconShadow,
            breadcrumbs: false,
        },
        {
            id: "store-discounts:",
            title: "Discounts",
            type: "item",
            url: "/stores/discounts",
            icon: icons.IconDiscount2,
            breadcrumbs: false,
        },
        {
            id: "feedback",
            title: "Feedback",
            type: "item",
            url: "/stores/feedback",
            icon: VscFeedback,
            breadcrumbs: false,
        },
    ],
};

export default store;
