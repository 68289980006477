import AxiosInstance from "../config/Helpers";

export const deleteMerchant = async (id) => {
    const res = await AxiosInstance.delete(`merchant/${id}`);
    return res.data;
};

export const getMerchant = async (id) => {
    const res = await AxiosInstance.get(`merchant-form-data/approve/${id}`);
    return res.data;
};

//update merchant
export const updateMerchant = async (id, body) => {
    const res = await AxiosInstance.patch(`merchant-form/${id}`, body);
    return res.data;
};
