import React from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { useOfferDetails, addToDatabase } from "./api";
import { Loading } from "../../../components/ui";
// import { map } from "underscore";
import CategorySelect from "../../../components/ui/CategorySelect";
import useCategories from "../../../views/stores/categories/hooks/useCategoriesQuery";
export default function AddToDdView() {
    const { id } = useParams();
    const { data, isPending, isError } = useOfferDetails(id);
    const { data: categories } = useCategories();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();
    const onSubmit = async (body) => {
        const response = await addToDatabase({
            ...body,
            name: data.offer.title,
            source: "slice",
            store_id: `slice-${data.offer.id}`,
            logo_url: data.offer.logo,
        });
        return response;
    };
    const { mutate } = useMutation({
        mutationFn: handleSubmit(onSubmit),
        onSuccess: () => {
            toast.success("Store added to database");
        },
    });
    if (isPending) return <Loading />;
    if (isError) return <div>Error</div>;
    return (
        <div className="">
            <pre>{JSON.stringify(status.data, null, 2)}</pre>
            <div className="flex flex-col items-center bg-white p-5 rounded-md">
                <img src={data.offer.logo} alt="" />
                <form className="max-w-screen-md mx-auto gap-y-5 flex flex-col">
                    <div className="">
                        <h1>
                            <span className="font-semibold">Name : </span>
                            {data.offer.title}
                        </h1>
                        <p>Description: {data.offer.description_lang.en}</p>
                    </div>
                    <div className="flex">
                        <h1 className="w-1/3">Title:</h1>
                        <input
                            {...register("title")}
                            placeholder="Enter your title"
                            className="px-3 py-1 w-2/3 h-8 rounded-md bg-gray-100"
                        />
                    </div>
                    <div className="flex">
                        <h1 className="w-1/3" htmlFor="reawad_rate">
                            Commission rate
                        </h1>
                        <input
                            {...register("commission_rate")}
                            className=" w-2/3  bg-gray-100 rounded-md px-3 h-8"
                            placeholder="Enter commission rate"
                        />
                    </div>
                    <div className="flex">
                        <h1 className="w-1/3" htmlFor="reawad_rate">
                            Points
                        </h1>
                        <input
                            {...register("points")}
                            className="w-2/3  bg-gray-100 rounded-md px-3 h-8"
                            placeholder="Enter points"
                        />
                    </div>
                    <div className="flex">
                        <h1 className="w-1/3" htmlFor="reawad_rate">
                            Tracking URL
                        </h1>
                        <input
                            {...register("tracking_url")}
                            className="w-2/3  bg-gray-100 rounded-md px-3 h-8"
                            placeholder="Enter tracking Url"
                        />
                    </div>
                    <div className="flex  items-center">
                        <h1 className="w-1/3">Category</h1>
                        <Controller
                            name="categories"
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                                <CategorySelect
                                    options={categories}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="flex">
                        <h1 className="w-1/3">Description</h1>
                        <textarea
                            {...register("description")}
                            placeholder="Enter your description"
                            className="w-2/3 h-32 bg-gray-100 p-2 rounded-md"
                        />
                    </div>
                    <div className="my-5">
                        <h1 className="mb-3">Terms & Conditions</h1>
                        <textarea
                            {...register}
                            placeholder="Enter your terms and conditions"
                            className="w-full p-2 rounded-md"
                        />
                    </div>
                    <Button
                        onClick={mutate}
                        variant="contained"
                        className="bg-indigo-500"
                    >
                        Add to database
                    </Button>
                </form>
            </div>
        </div>
    );
}
