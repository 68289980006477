import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import GiftPayCard from "./giftPayCard";

function GiftcardMain() {
  const [value, setValue] = useState('0');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Gift Pay" value={'0'} />
            <Tab label="Prezzee" value={'1'} />
          </TabList>
        </Box>
        <TabPanel value={'0'}><GiftPayCard activeTab='giftpay' /></TabPanel>
        <TabPanel value={'1'}><GiftPayCard activeTab='prezzee' /></TabPanel>
      </TabContext>
    </div>
  );
}

export default GiftcardMain;
