import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import axiosInstance from "../../../../config/Helpers";

const useOffer = (id) => {
    return useQuery({
        queryKey: ["everflow-offers", id],
        // queryFn: getEverflowOffer(id),
        queryFn: async () => {
            try {
                const response = await axios.get(
                    `https://api.eflow.team/v1/affiliates/offers/${id}`,
                    {
                        headers: {
                            "X-Eflow-API-Key": "rCBqC8jtTyaG1lHA8J0G7w",
                        },
                    }
                );
                return response.data;
            } catch (error) {
                console.error("Error fetching offers:", error);
                throw error;
            }
        },
        retry: 3, // Retry the API request 3 times before giving up
        retryDelay: 1000, // Delay between retry attempts in milliseconds
        onError: (error) => {
            console.error("Error fetching offers:", error);
            // Implement fallback mechanism or return a default value
            // ...
        },
    });
};

export default useOffer;
