import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../config/Helpers";

export default function useGetMerchant(id) {
    return useQuery({
        queryKey: ["com-fac-merchant", id],
        queryFn: async () => {
            const { data } = await axiosInstance.get(
                `affiliate-program/comfact-api/${id}`
            );
            return data;
        },
    });
}
