import React from "react";
import axios from "axios";
import { base_url } from "../../../constants";

const uploadLogoFunction = async (formData, merchantId) => {
    const token = localStorage.getItem("accessToken");
    const res = await axios.post(
        `${base_url}/merchant/${merchantId}/logo`,
        formData,
        {
            headers: {
                authorization: `Bearer ${token}`, // for Node.js Express back-end
            },
        }
    );
    return res.data;
};

export default uploadLogoFunction;
