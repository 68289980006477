import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../config/Helpers";

export default function useGetAllMerchant() {
    return useQuery({
        queryKey: ["comfact-all"],
        queryFn: async () => {
            const { data } = await axiosInstance.get(
                "affiliate-program/search?source=commission-factory"
            );
            return data;
        },
    });
}
