import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import CheckboxTree from "../CheckboxTree";

const CategorySelect = ({ options, value, onChange }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen}>
                Select Categories
            </Button>
            <Dialog
                // maxWidth='lg'
                fullWidth={true}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle sx={{ fontSize: 20 }}>
                    Select Categories
                </DialogTitle>
                <DialogContent>
                    <CheckboxTree nodes={options} value={value} onChange={onChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Done</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default CategorySelect;