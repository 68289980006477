import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../config/Helpers";
const getStatus = async (id) => {
    const res = await axiosInstance.get(`affiliate-program/status/${id}`);
    return res.data;
};

export default function useStoreStatus(id) {
    return useQuery({
        queryKey: ["store-status", id],
        queryFn: () => getStatus(id),
    });
}
