import axiosInstance from "../../../../config/Helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const updateGiftCard = async (payload) => {
    const { _id, ...rest } = payload
    const res = await axiosInstance.put(`giftcard/update-gift-card/${_id}`, rest);
    return res.data;
};

const useGiftCardMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: updateGiftCard,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['get-gift-cards'] });
        },
    });
    return mutation
}

export default useGiftCardMutation;