import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { base_url } from "../../../constants";

export default function useGetMerchantInfo(merchantId) {
    return useQuery({
        queryKey: [merchantId],
        queryFn: async () => {
            const res = await axios.get(`${base_url}/merchant/${merchantId}`);
            return res.data;
        },
    });
}
