import { useNavigate, useRoutes } from "react-router-dom";
import React from "react";
// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { useEffect } from "react";

// ==============================|| ROUTING RENDER ||============================== //
function NotFound() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("accessToken")) {
            navigate("/login");
        }
    });
    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <div
                style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    marginTop: "100px",
                }}
            >
                <h2>404 - Not Found</h2>
                <p>Sorry, the page you are looking for does not exist.</p>
            </div>
        </div>
    );
}
export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes,
        AuthenticationRoutes,
        {
            path: "*",
            element: <NotFound />,
        },
    ]);
}
