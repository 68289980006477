import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { base_url } from "../../../constants";

export const updateMerchat = async (formData, merchantId) => {
    const token = localStorage.getItem("accessToken");
    const res = await axios.patch(
        `${base_url}/merchant/${merchantId}`,
        formData,
        {
            headers: {
                authorization: `Bearer ${token}`, // for Node.js Express back-end
            },
        }
    );
    return res.data;
};

export default function useUpdateMerchant(merchantId) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (formData) => updateMerchat(formData, merchantId),
        onSuccess: () => {
            queryClient.invalidateQueries([merchantId]);
            queryClient.refetchQueries([merchantId]);
        },
    });
}
