import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    Modal,
    Paper,
    TableFooter,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
// import { rows } from "./dummy-data/rows";
import { TablePaginationActions } from "./utils/tablePaginationActions";
import TableHead from "@mui/material/TableHead";
import SearchInput from "./searchInput";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDownAlt";

import { useNavigate } from "react-router";
import axiosInstance from "../../../config/Helpers";
import "./button.css";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
};

const Applications = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDisapprove, setOpenDisapprove] = useState(false);
    const navigate = useNavigate();

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseDisapprove = () => {
        setOpenDisapprove(false);
    };

    const openModal = () => {
        setOpen(true);
    };
    const openModalDisapprove = () => {
        setOpenDisapprove(true);
    };
    const getApplications = () => {
        axiosInstance
            .get(`merchant-form-data`)
            .then((response) => {
                console.log(response.data);
                setRows(response.data);
            })
            .catch((err) => console.log(err));
    };

    function Spacer() {
        return (
            <Box
                sx={{
                    height: 20,
                }}
            />
        );
    }

    useEffect(() => {
        getApplications();
    }, []);

    return (
        <>
            {rows ? (
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 500 }}
                        aria-label="custom pagination table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <LocalOfferIcon />
                                        <h2>Applications</h2>
                                    </Grid>
                                </TableCell>
                                <TableCell colSpan={2} />
                                <TableCell colSpan={3}>
                                    <SearchInput />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">ID</TableCell>
                                <TableCell align="right">Company</TableCell>
                                <TableCell align="right">
                                    Trading Name
                                </TableCell>
                                <TableCell align="right">
                                    Application Submit Date/Time
                                </TableCell>
                                <TableCell align="right">NZBN</TableCell>
                                <TableCell align="right">
                                    Company Type
                                </TableCell>
                                <TableCell align="right">Industry</TableCell>
                                <TableCell align="right">Show More</TableCell>
                                <TableCell align="right">
                                    Action (Approve / Disapprove)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 && rows
                                ? rows.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : rows
                            ).map((row, index) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {/* {row._id} */}
                                        {index + 1}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="right"
                                    >
                                        {row.business_name}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="right"
                                    >
                                        {row.brand_trading}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="right"
                                    >
                                        {row.createdDate}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="right"
                                    >
                                        {row.nzbn}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="right"
                                    >
                                        {row.business_type}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="right"
                                    >
                                        {row.industry}
                                    </TableCell>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="center"
                                    >
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                navigate(
                                                    `/applicationdetails/${row._id}`
                                                )
                                            }
                                        >
                                            <RemoveRedEyeOutlinedIcon />
                                        </div>
                                    </TableCell>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style} component="form">
                                            <h1
                                                style={{ marginBottom: "20px" }}
                                            >
                                                Are you sure you want to Approve
                                            </h1>
                                            <button
                                                onClick={() => {
                                                    axiosInstance
                                                        .put(
                                                            `merchant-form-data/approve/${row._id}`
                                                        )
                                                        .then((response) => {
                                                            getApplications();
                                                        });
                                                }}
                                                className="button-21"
                                            >
                                                Yes
                                            </button>
                                        </Box>
                                    </Modal>
                                    <Modal
                                        open={openDisapprove}
                                        onClose={handleCloseDisapprove}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style} component="form">
                                            <h1>
                                                Are you sure you want to
                                                Disapprove
                                            </h1>
                                            <button
                                                onClick={() => {
                                                    axiosInstance
                                                        .delete(
                                                            `merchant-form-data/disapprove/${row._id}`
                                                        )
                                                        .then((response) => {
                                                            getApplications();
                                                        });
                                                }}
                                                className="button-21"
                                            >
                                                Yes
                                            </button>
                                        </Box>
                                    </Modal>
                                    <TableCell
                                        style={{ width: 160 }}
                                        align="right"
                                    >
                                        <IconButton
                                            aria-label="approve"
                                            onClick={() => {
                                                openModal();
                                            }}
                                        >
                                            <ThumbUpIcon />
                                        </IconButton>
                                        <IconButton
                                            aria-label="disapprove"
                                            onClick={() => {
                                                openModalDisapprove();
                                            }}
                                        >
                                            <ThumbDownIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        5,
                                        10,
                                        25,
                                        { label: "All", value: -1 },
                                    ]}
                                    colSpan={9}
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page",
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            ) : (
                <CircularProgress />
            )}
        </>
    );
};

export default Applications;
