import axiosInstance from "../../../../config/Helpers";
import { useQuery } from "@tanstack/react-query";

export default function useDb(id) {
    return useQuery({
        queryKey: ["everflow", id],
        queryFn: async () =>
            await axiosInstance
                .get(`affiliate-program/search?source=everflow&storeId=${id}`)
                .then((res) => res.data),
    });
}
