import React from "react";
import { Avatar, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { patchMerchant } from "../api";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import CategorySelect from "../../../../components/ui/CategorySelect";
import useCategories from "../../../../views/stores/categories/hooks/useCategoriesQuery";

export default function UpdateMerchant({ data }) {
    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            ...data.merchant,
        },
    });
    const { data: categories } = useCategories();

    const { mutate } = useMutation({
        mutationFn: (body) => patchMerchant(data.merchant.store_id, body),
        onSuccess: () => {
            toast.success("Store Updated Successfully");
            queryClient.invalidateQueries([
                "commission-factory",
                data.merchant.store_id,
            ]);
            queryClient.refetchQueries([
                "commission-factory",
                data.merchant.store_id,
            ]);
        },
    });

    const onSubmit = (data) => {
        mutate({
            ...data,
        });
    };
    return (
        <main className="max-w-screen-md mx-auto">
            <div className="flex items-center">
                <Avatar className="mr-2" src={data.merchant.logo_url} />
                <p>{data.merchant.name}</p>
            </div>
            <div className="flex my-2 items-center">
                <h1 className="w-1/3">ID:</h1>
                <h1>{data.merchant.store_id}</h1>
            </div>

            <div className="flex my-2 items-center">
                <h1 className="w-1/3">Tracking Url:</h1>
                <p>{data.merchant.tracking_url}</p>
            </div>
            <form className="flex flex-col gap-3">
                <div className="flex  items-center">
                    <h1 className="w-1/3">Title</h1>
                    <input
                        placeholder="Enter Title"
                        className="py-2 px-2 w-1/3 rounded-md"
                        {...register("title")}
                    />
                </div>
                {/* <div className="flex  items-center">
                    <h1 className="w-1/3">Reward Rate</h1>
                    <input
                        placeholder="Enter reward Rate"
                        className="py-2 px-2 w-1/3 rounded-md"
                        {...register("reward_rate")}
                    />
                </div> */}
                <div className="flex  items-center">
                    <h1 className="w-1/3">Commission Rate:</h1>
                    <input
                        placeholder="Enter reward Rate"
                        className="py-2 px-2 w-1/3 rounded-md"
                        {...register("commission_rate")}
                    />
                </div>
                <div className="flex  items-center">
                    <h1 className="w-1/3">Points</h1>
                    <input
                        placeholder="Enter points"
                        className="py-2 px-2 w-1/3 rounded-md"
                        {...register("points")}
                    />
                </div>
                <div className="flex  items-center">
                    <h1 className="w-1/3">Priority</h1>
                    <input
                        placeholder="Enter Priority"
                        type="number"
                        className="py-2 px-2 w-1/3 rounded-md"
                        {...register("priority")}
                    />
                </div>
                <div className="flex  items-center">
                    <h1 className="w-1/3">Month offset</h1>
                    <input
                        defaultValue={
                            data.merchant.lock_time_period?.month_offset
                                ? data.merchant.lock_time_period?.month_offset
                                : 0
                        }
                        placeholder="Enter Month offset"
                        className="py-2 px-2 w-1/3 rounded-md"
                        {...register("month_offset")}
                    />
                </div>
                <div className="flex  items-center">
                    <h1 className="w-1/3">Days offset</h1>
                    <input
                        defaultValue={
                            data.merchant.lock_time_period?.day_offset
                                ? data.merchant.lock_time_period?.day_offset
                                : 0
                        }
                        placeholder="Enter Days offset"
                        className="py-2 px-2 w-1/3 rounded-md"
                        {...register("day_offset")}
                    />
                </div>
                <div className="flex items-center ">
                    <h1 className="w-1/3">Categories</h1>
                    <Controller
                        name="categories"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                            <CategorySelect options={categories} {...rest} />
                        )}
                    />
                </div>
                <div className="flex  items-start">
                    <h1 className="w-1/3">Description</h1>
                    <textarea
                        placeholder="Write the description"
                        className="py-2 px-2 w-2/3 h-40 rounded-md"
                        {...register("description")}
                    />
                </div>
                <div className="flex  items-start">
                    <h1 className="w-1/3">Terms</h1>
                    <textarea
                        placeholder="Write the description"
                        className="py-2 px-2 w-2/3 h-40 rounded-md"
                        {...register("terms")}
                    />
                </div>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    className="bg-indigo-500"
                    variant="contained"
                >
                    <h1>Update</h1>
                </Button>
            </form>
        </main>
    );
}
