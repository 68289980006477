/** @format */

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import React from "react";

import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { UserCard } from "../../ui-component/cards/UserCard";
import { useNavigate, useParams } from "react-router";

import MerchantListing from "./InfoForm";
import { deleteMerchant, getMerchant } from "../../services/merchant";
import { useQuery } from "@tanstack/react-query";

export const iconStyle = {
  color: "gray", // Change this to the desired color
};
const ApplicationDetails = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const merchant = useQuery({
    queryKey: ["merchant", id],
    queryFn: () => getMerchant(id),
  });

  const regex = /^(?!(https:\/\/|http:\/\/))/; // Check if there is no http:// or https:// at the beginnin
  const { data } = merchant;
  if (merchant.isPending) return <div>Loading...</div>;
  if (merchant.isError) return <div> Something went wrong</div>;

  return (
    <>
      {data && (
        <div className="bg-white">
          <div className="flex flex-row justify-between align-middle p-3">
            <div className="flex flex-row align-middle items-center gap-1">
              <div
                className="cursor-pointer pr-3 "
                onClick={() => navigate(-1)}
              >
                <WestOutlinedIcon />
              </div>
              <BusinessOutlinedIcon style={{ color: "#0ea5e9" }} />
              <h2 className="font-semibold text-xl ">Customer detail</h2>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row ">
            <div className="w-full p-3">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        Brand Name
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.business_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        Brand Trading Name
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.brand_trading}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        Business type
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.business_type}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        Business Type
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.business_type}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        NZBN
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.nzbn}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        Main Industry
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.industry}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        Website
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.url.replace(regex, "https://")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="text-base font-medium text-gray-500"
                        component="th"
                        scope="row"
                      >
                        Store
                      </TableCell>
                      <TableCell
                        className="font-semibold text-base"
                        align="left"
                      >
                        {merchant.data.store_type}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="pt-5">
                <div className="flex flex-row justify-between align-middle items-center  border-b pb-3 border-gray-300">
                  <p className="font-semibold text-xl text-blue-500">
                    Contact Info
                  </p>
                </div>

                <div className="mt-4">
                  <UserCard
                    name={data?.first_name}
                    city={data?.city}
                    phone={data?.phone}
                    email={data?.email}
                    postcode={data?.postcode}
                    street={data?.street}
                  />
                </div>
                {/* Delete merchant button */}
              </div>
            </div>
            <div className="bg-[#F6F9FD] w-full p-3 mb-15 rounded-lg"></div>
          </div>
        </div>
      )}
    </>
  );
};
export default ApplicationDetails;
