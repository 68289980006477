import { useQuery } from "@tanstack/react-query";
import axios from "axios";
const url =
    "https://api.impact.com/Mediapartners/IR5GsTxup5m55081916U9UhzvErjwcPmB1/Campaigns";
export const getAllCampaigns = async () => {
    const { data } = await axios.get(url, {
        headers: {
            Authorization:
                "Basic SVI1R3NUeHVwNW01NTA4MTkxNlU5VWh6dkVyandjUG1CMTpvVmppQmhMLUJzY1Q4WnVzRHdhUEIuS1Zmbmo4UlV2Zw==",
            Accept: "application/json",
        },
    });
    return data;
};

export default function useGetCampaigns() {
    return useQuery({
        queryKey: ["impact-all-campaigns"],
        queryFn: getAllCampaigns,
        staleTime: Infinity,
        cacheTime: Infinity,
    });
}
