import React from "react";
import { Button } from "@mui/material";
// import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
import { Controller, useForm } from "react-hook-form";
import useCategories from "../../../views/stores/categories/hooks/useCategoriesQuery";
import CategorySelect from "../../../components/ui/CategorySelect";
import useGiftCardMutation from "./hooks/useGiftCardMutation";
import toast from "react-hot-toast";

const GiftCardEditDialog = ({ data }) => {
    const { data: categories } = useCategories();
    const giftCardMutation = useGiftCardMutation();

    const [open, setOpen] = React.useState(false);
    const { register, handleSubmit, control } = useForm({
        defaultValues: {
            _id: data._id,
            validity: data.validity,
            categories: data.categories,
        },
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant={open ? "text" : "outlined"}
                onClick={handleClickOpen}
            >
                Edit
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: "form",
                    onSubmit: handleSubmit(async (values) => {
                        await giftCardMutation.mutateAsync({ ...values });
                        toast.success("updated successfully");
                        handleClose();
                    }),
                }}
            >
                <DialogTitle>Edit Gift Card</DialogTitle>
                <DialogContent>
                    {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText> */}
                    <div className="my-4">
                        <h1 className="mb-2">Expiry</h1>
                        <input
                            type="text"
                            {...register("validity")}
                            className=" rounded-md bg-gray-100 px-2 py-2 w-full"
                        />
                    </div>

                    <div className="my-4">
                        <h1 className="mb-2">Categories</h1>
                        <Controller
                            name="categories"
                            control={control}
                            render={({ field: { ref, ...rest } }) => (
                                <CategorySelect
                                    options={categories}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default GiftCardEditDialog;
