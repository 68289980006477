import React, { useState } from "react";
import { PhotoSizeSelectLargeOutlined } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import { useParams } from "react-router";
import { deleteLogo } from "../../api/useDeleteLogo";
import uploadLogoFunction from "../../api/useUploadLogo";
function UploadLogo({ logo }) {
    const [image, setImage] = useState(null);
    const onDrop = (acceptedFiles) => {
        // Set the uploaded image to state
        setImage(acceptedFiles[0]);
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { id } = useParams();
    // const { mutate } = useDeleteLogo(id);
    const handleDelete = async () => {
        const res = await deleteLogo(id, logo.public_id);
        console.log(res);
    };

    const handleUpload = async () => {
        try {
            const formData = new FormData();
            formData.append("logo", image);
            const res = await uploadLogoFunction(formData, id);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <section className="col-span-full text-center  mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 ">
            {/* {logo ? (
                <div className="flex flex-col justify-center py-10">
                    <img
                        src={logo.url}
                        alt="logo"
                        className="h-32 w-32 rounded-full"
                    />
                    <div className="flex mt-4 space-x-5 justify-center">
                        <Button variant="contained" className=" bg-slate-600">
                            Change
                        </Button>
                        <Button
                            onClick={handleDelete}
                            variant="contained"
                            className=" bg-red-600"
                        >
                            Remove
                        </Button>
                    </div>
                </div> */}

            {image ? (
                <div className="py-2 flex flex-col justify-center">
                    <h4 className="mb-2">Uploaded Image:</h4>

                    <img
                        src={URL.createObjectURL(image)} // Use createObjectURL to generate a preview URL
                        alt="Uploaded"
                        className="w-52 h-32"
                    />
                    <div className="flex w-full space-x-5 text-center mt-4">
                        <Button
                            variant="contained"
                            className="bg-red-500 text-white"
                            onClick={() => setImage(null)}
                        >
                            Remove
                        </Button>
                        <Button
                            variant="contained"
                            className="bg-indigo-500 text-white"
                            onClick={() => handleUpload()}
                        >
                            Upload
                        </Button>
                    </div>
                </div>
            ) : (
                <div {...getRootProps({ className: "py-10 px-6 w-full" })}>
                    <PhotoSizeSelectLargeOutlined
                        className="mx-auto h-12 w-12 text-gray-300"
                        aria-hidden="true"
                    />
                    <p>Upload your logo</p>
                    <input {...getInputProps()} />
                </div>
            )}
        </section>
    );
}

export default UploadLogo;
