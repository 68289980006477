// assets
import { IconTicket, IconHelp } from '@tabler/icons';

// constant
const icons = { IconTicket, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'contact-us',
      title: 'Contact Us',
      type: 'item',
      url: '/contact-us',
      icon: icons.IconTicket,
      breadcrumbs: false
    },
    {
      id: 'documentation',
      title: 'Documentation',
      type: 'item',
      url: 'https://codedthemes.gitbook.io/berry/',
      icon: icons.IconHelp,
      external: true,
      target: true
    }
  ]
};

export default other;
