import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import UpsertCategoryModal from "./UpsertCategoryModal";
import UpsertSubCategoryModal from "./UpsertSubCategoryModal";

const CategoryTable = ({ data }) => {
    return (
        <TableContainer component={Paper}>
            <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
            >
                <TableHead>
                    <TableRow>
                        {/* <TableCell align="left">ID</TableCell> */}
                        <TableCell align="left">
                            Name
                        </TableCell>
                        <TableCell align="left">
                            SubCategories
                        </TableCell>
                        <TableCell align="left">
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row, index) => (
                        <TableRow key={row._id}>
                            {/* <TableCell
                                component="th"
                                scope="row"
                            >
                                {index + 1}
                            </TableCell> */}
                            <TableCell
                                align="left"
                            >
                                {row.name}
                            </TableCell>
                            <TableCell
                                align="left"
                            >
                                <UpsertSubCategoryModal data={row.subCategories} id={row._id} />
                            </TableCell>

                            <TableCell
                                align="left"
                            >
                                <UpsertCategoryModal id={row._id} category={{ name: row.name, icon: row.icon }} />

                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CategoryTable