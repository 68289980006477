import axios from "axios";
import { base_url } from "../../../constants";

export const getReviews = async (merchantId) => {
    const token = localStorage.getItem("accessToken");
    const res = await axios.get(`${base_url}/review/merchant/${merchantId}`, {
        headers: {
            authorization: `Bearer ${token}`, // for Node.js Express back-end
        },
    });
    return res.data;
};
export const updateReview = async (data) => {
    const token = localStorage.getItem("accessToken");
    const res = await axios.patch(`${base_url}/review`, data, {
        headers: {
            authorization: `Bearer ${token}`, // for Node.js Express back-end
        },
    });
    return res.data;
};

export const getDiscountReview = async (discountId) => {
    const token = localStorage.getItem("accessToken");
    const res = await axios.get(`${base_url}/review/discount/${discountId}`, {
        headers: {
            authorization: `Bearer ${token}`, // for Node.js Express back-end
        },
    });
    return res.data;
};

export const updateDiscountReview = async (data) => {
    const token = localStorage.getItem("accessToken");
    const res = await axios.patch(`${base_url}/review/discount`, data, {
        headers: {
            authorization: `Bearer ${token}`, // for Node.js Express back-end
        },
    });
    return res.data;
};
