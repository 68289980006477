import React from "react";
import { map } from "lodash";

import ImpactStoreCard from "./impact-store-card";

export default function ImpactStores(props) {
    const { data, isPending, isError } = props;
    if (isPending) return <div>Loading..</div>;
    if (isError) return <div>Something went wrong</div>;
    return (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 pt-5">
            {map(data.Campaigns, (campaign) => {
                return (
                    <ImpactStoreCard
                        key={campaign.CampaignId}
                        campaign={campaign}
                    />
                );
            })}
        </div>
    );
}
