import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import MultiStep from "react-multistep";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { Loading } from "../../components/ui";
import ConfirmationDialog from "./ConfirmationDialog";
import { useGetMerchantInfo } from "../../features/merchant/api";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { UserCard } from "../../ui-component/cards/UserCard";
import {
    ApiStatus,
    MerchantStatus,
    PaymentStatus,
    SuccessListing,
} from "./BusinessListing";
import MerchantListing from "./MerchantRegistration";
import { deleteMerchant } from "../../services/merchant";

import Review from "./Review";

export const iconStyle = {
    color: "gray", // Change this to the desired color
};

const tabs = ["Info", "Tags"];

export const CustomerDetails = (props) => {
    const [steps, setSteps] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);

    let { id } = useParams();
    const navigate = useNavigate();

    const merchant = useGetMerchantInfo(id);
    const handleDeleteMerchant = async () => {
        const res = await deleteMerchant(id);
        if (res) {
            navigate("/stores/store-orders");
        }
    };

    const regex = /^(?!(https:\/\/|http:\/\/))/; // Check if there is no http:// or https:// at the beginnin
    const { data } = merchant;

    if (merchant.isPending) return <Loading />;
    if (merchant.isError) return <div> Something went wrong</div>;

    return (
        <>
            {data && (
                <div className="bg-white rounded-md p-2">
                    <div className="flex flex-row justify-between align-middle p-3">
                        <div className="flex flex-row align-middle items-center gap-1">
                            <div
                                className="cursor-pointer pr-3 "
                                onClick={() => navigate(-1)}
                            >
                                <WestOutlinedIcon />
                            </div>
                            <BusinessOutlinedIcon
                                style={{ color: "#0ea5e9" }}
                            />
                            <h2 className="font-semibold text-xl mb-2 ">
                                Customer detail
                            </h2>
                        </div>
                    </div>

                    <div className="flex">
                        {tabs.map((v, i) => (
                            <div
                                className={`p-2 px-3 rounded-md cursor-pointer hover:bg-neutral-200 ${
                                    selectedTab === i && " bg-neutral-200"
                                }`}
                                onClick={() => setSelectedTab(i)}
                                key={i}
                            >
                                {v}
                            </div>
                        ))}
                    </div>

                    {selectedTab === 0 && (
                        <div className="flex flex-col  sm:flex-row ">
                            <div className="w-full p-3 ">
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Brand Name
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {
                                                        merchant.data
                                                            .business_name
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Brand Trading Name
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {
                                                        merchant.data
                                                            .brand_trading
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Business type
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {
                                                        merchant.data
                                                            .business_type
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Business Type
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {
                                                        merchant.data
                                                            .business_type
                                                    }
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    NZBN
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {merchant.data.nzbn}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Main Industry
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {merchant.data.industry}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Website
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {merchant.data.url.replace(
                                                        regex,
                                                        "https://"
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    className="text-base font-medium text-gray-500"
                                                    component="th"
                                                    scope="row"
                                                >
                                                    Store
                                                </TableCell>
                                                <TableCell
                                                    className="font-semibold text-base"
                                                    align="left"
                                                >
                                                    {merchant.data.store_type}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className="pt-5">
                                    <div className="flex flex-row justify-between align-middle items-center  border-b pb-3 border-gray-300">
                                        <p className="font-semibold text-xl text-blue-500">
                                            Contact Info
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <UserCard
                                            name={
                                                data?.first_name +
                                                " " +
                                                data?.last_name
                                            }
                                            city={data?.city}
                                            phone={data?.phone}
                                            email={data?.email}
                                            postcode={data?.postcode}
                                            street={data?.street}
                                        />
                                    </div>
                                    {/* Delete merchant button */}
                                    <ConfirmationDialog
                                        handleDeleteMerchant={
                                            handleDeleteMerchant
                                        }
                                    />
                                </div>
                            </div>
                            <div className="bg-[#F6F9FD] w-full p-3 mb-15 rounded-lg">
                                <div>
                                    <div className="flex flex-row justify-between align-middle items-center  border-b pb-3 border-gray-300 mt-10">
                                        <p className="font-semibold text-xl text-blue-500">
                                            List to App
                                        </p>
                                    </div>
                                    <div className="ml-10">
                                        <MultiStep
                                            direction={false}
                                            title="Process"
                                            activeStep={steps}
                                            prevButton={{
                                                title: "Back",
                                                style: {
                                                    background: "red",
                                                    padding:
                                                        "10px 20px 10px 20px",
                                                    margin: "10px",
                                                    fontSize: "12px",
                                                    borderRadius: "15px",
                                                    backgroundColor: "#F53F3F",
                                                    color: "white",
                                                },
                                            }}
                                            nextButton={{
                                                title: "Forward",

                                                style: {
                                                    background: "blue",
                                                    padding:
                                                        "10px 20px 10px 20px",
                                                    margin: "10px",
                                                    fontSize: "12px",
                                                    borderRadius: "15px",
                                                    color: "white",
                                                },
                                            }}
                                            showTitles={true}
                                        >
                                            <MerchantListing title="Merchant Information" />
                                            <MerchantStatus title="Merchant Registration" />
                                            <PaymentStatus title="Payment Status" />
                                            <ApiStatus title="API status" />
                                            <SuccessListing title="listing Complete" />
                                        </MultiStep>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedTab === 1 && <Review />}
                </div>
            )}
        </>
    );
};
