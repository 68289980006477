import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Box, Typography } from "@mui/material";
import axiosInstance from "../../config/Helpers";

const Notification = () => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [response, setResponse] = useState("");
    const [error, setError] = useState("");

    const handleBodyChange = (event) => {
        const value = event.target.value;
        const wordCount = value
            .split(/\s+/)
            .filter((word) => word.length > 0).length;

        if (wordCount <= 100) {
            setBody(value);
            setError("");
        } else {
            setError("Body cannot exceed 100 words");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (body.split(/\s+/).filter((word) => word.length > 0).length > 100) {
            setError("Body cannot exceed 100 words");
            return;
        }
        try {
            const res = await axiosInstance.post(
                "/sendNotificationToAllUsers",
                {
                    title,
                    body,
                }
            );
            setResponse(res.data.message);
        } catch (error) {
            setResponse("Error sending notification");
            console.error(error);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography variant="h4" gutterBottom>
                Send Notification to all users
            </Typography>
            <form onSubmit={handleSubmit} style={{ width: "300px" }}>
                <TextField
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                    required
                />
                <TextField
                    label="Body"
                    value={body}
                    onChange={handleBodyChange}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    required
                    error={Boolean(error)}
                    helperText={error}
                />
                <Button type="submit" fullWidth>
                    Send
                </Button>
            </form>
            {response && (
                <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{ marginTop: "20px" }}
                >
                    {response}
                </Typography>
            )}
        </Box>
    );
};

export default Notification;
