import React from "react";
import { Loading } from "../../../components/ui";
import { map } from "underscore";
import { Link } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";

export default function SliceOffers({ data, isPending, isError }) {
    if (isPending) return <Loading />;
    if (isError) return <div>Error</div>;
    return (
        <div>
            <h1 className="text-2xl my-4">Slice Offers</h1>

            <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
                {map(data.offers, (offer) => (
                    <Link key={offer.id} to={`/stores/slice/${offer.id}`}>
                        <div
                            className="p-2 bg-white rounded-md shadow-md flex flex-col justify-center  items-center "
                            key={offer.id}
                        >
                            <div className="">
                                {offer.addedToDatabase ? (
                                    <FcCheckmark size={30} />
                                ) : (
                                    <AiOutlineClose color="red" size={30} />
                                )}
                            </div>
                            <div className="flex items-center justify-center h-60">
                                <img
                                    className="mb-4  "
                                    src={offer.logo}
                                    alt={offer.title}
                                />
                            </div>
                            <div className="flex items-center">
                                <h1 className="text-lg font-bold">
                                    {offer.title}
                                </h1>
                            </div>
                        </div>
                    </Link>
                ))}
            </ul>
        </div>
    );
}
