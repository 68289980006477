import React from "react";
import { CircularProgress } from "@mui/material";

function Loading() {
    return (
        <div className="flex justify-center items-center min-w-fit py-20">
            <CircularProgress />
        </div>
    );
}

export default Loading;
