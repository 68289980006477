import React, { useCallback, useEffect, useState } from "react";
import {
    getReviews,
    updateReview,
} from "../../features/merchant/api/useReview";
import { IoIosClose, IoIosAddCircleOutline } from "react-icons/io";
import toast from "react-hot-toast";
import { useParams } from "react-router";

const Review = () => {
    let { id } = useParams();

    const [review, setReview] = useState([]);
    const [showReviewTagModal, setShowReviewTagModal] = useState(false);
    const [modalData, setModalData] = useState();

    const onTagAdded = (_id, tagName) => {
        setReview((ov) => {
            ov.forEach((v) => {
                if (v._id === _id) {
                    v.tags.push(tagName);
                }
            });
            return [...ov];
        });
    };
    const removeTag = (_id, index) => {
        setReview((ov) => {
            ov.forEach((v) => {
                if (v._id === _id) {
                    v.tags.splice(index, 1);
                }
            });
            return [...ov];
        });
    };
    const updateTitle = (_id, title) => {
        setReview((ov) => {
            ov.forEach((v) => {
                if (v._id === _id) {
                    v.title = title;
                }
            });
            return [...ov];
        });
    };

    const handleReviewSave = useCallback(async () => {
        updateReview(review).then(() => {
            toast.success("Reviews updated!");
        });
    }, [review]);

    useEffect(() => {
        getReviews(id).then((resData) => {
            console.log(resData);
            setReview(resData.data);
        });
        return () => {};
    }, []);

    return (
        <div className="flex flex-col gap-2 m-4">
            {review.map((v, i) => {
                return (
                    <div key={i} className="flex flex-col gap-2 border-b pb-3">
                        <div className="flex items-center gap-2">
                            <div>Icon :</div>
                            <div>
                                <img
                                    src={v.image.url}
                                    className="h-[40px]"
                                    alt=""
                                />
                            </div>
                            <div>Score :</div>
                            <div>{v.score}</div>
                        </div>
                        <div className="flex gap-2">
                            <div>Title :</div>
                            <div>
                                <input
                                    type="text"
                                    className="w-[400px] border px-1"
                                    defaultValue={v.title}
                                    onChange={(e) =>
                                        updateTitle(v._id, e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex gap-2 items-center" key={i}>
                            <div>Tags :</div>
                            <div className="w-[400px] flex flex-wrap gap-2 p-2 border min-h-[50px]">
                                {v.tags.map((t, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="flex gap-2 items-center p-1 rounded-lg border whitespace-nowrap"
                                        >
                                            <div>{t}</div>{" "}
                                            <div
                                                className="hover:bg-neutral-200 p-1 rounded-lg"
                                                onClick={() =>
                                                    removeTag(v._id, i)
                                                }
                                            >
                                                <IoIosClose />
                                            </div>
                                        </div>
                                    );
                                })}
                                <IoIosAddCircleOutline
                                    size={20}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setModalData(v);
                                        setShowReviewTagModal(true);
                                    }}
                                />
                            </div>
                            <div></div>
                        </div>
                    </div>
                );
            })}
            <button
                className="p-2 px-3 w-[100px] rounded bg-blue text-white"
                onClick={handleReviewSave}
            >
                Save
            </button>
            {showReviewTagModal && (
                <Modal
                    setShow={setShowReviewTagModal}
                    data={modalData}
                    onSubmit={(_id, tagName) => onTagAdded(_id, tagName)}
                />
            )}
        </div>
    );
};

export default Review;

const Modal = ({ onSubmit, setShow, data }) => {
    const [tagName, setTagName] = useState("");
    return (
        <div
            className={`fixed z-10 overflow-y-auto top-0 w-full left-0`}
            id="modal"
        >
            <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                    &#8203;
                </span>
                <div
                    className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <label className="font-medium text-gray-800">
                            Tag Name
                        </label>
                        <input
                            type="text"
                            value={tagName}
                            onChange={(e) => {
                                setTagName(e.target.value);
                            }}
                            className="w-full outline-none rounded bg-gray-100 p-2 mt-2 mb-3"
                        />
                    </div>
                    <div className="bg-gray-200 px-4 py-3 text-right">
                        <button
                            type="button"
                            className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            <i className="fas fa-times"></i> Cancel
                        </button>
                        <button
                            type="button"
                            className="py-2 px-4 bg-blue text-white rounded hover:bg-blue-700 mr-2"
                            onClick={() => {
                                if (tagName === "") return;
                                onSubmit(data._id, tagName);
                                setShow(false);
                            }}
                        >
                            <i className="fas fa-plus"></i> Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
