import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";

const CheckboxTree = ({ nodes, value = [], onChange }) => {

    const handleCheckboxChange = (event, ancestor, node) => {
        const { checked } = event.target;

        if (!ancestor) {
            const parentCategory = nodes?.find(category => category._id === node._id);
            // Remove parent category if unchecked
            if (!checked) {
                const parentCategory = value.find(item => item._id === node._id);
                onChange(value.filter(item => item._id !== parentCategory._id));
                return

            }
            // Add parent category if checked
            if (checked) {
                onChange([...value, parentCategory]);
                return
            }
        }

        if (ancestor && node) {
            // Add or remove subcategory based on checkbox state
            const parentCategory = nodes?.find(category => category._id === ancestor._id);
            const subCategory = parentCategory?.subCategories?.find(sub => sub._id === node._id);
            const selecetedParent = value?.find(item => item._id === ancestor._id)
            if (selecetedParent) {
                if (checked) {
                    onChange(
                        [
                            ...value.filter(i => i._id !== parentCategory._id),
                            {
                                ...selecetedParent,
                                subCategories: [...selecetedParent.subCategories, subCategory]

                            }
                        ]
                    );
                    return
                }
                if (!checked) {
                    onChange(
                        [
                            ...value.filter(i => i._id !== parentCategory._id),
                            {
                                ...selecetedParent,
                                subCategories: [...selecetedParent.subCategories.filter(i => i._id !== node._id)]
                            }
                        ]
                    );
                    return
                }
            }
            return onChange([...value, { ...parentCategory, subCategories: [subCategory] }]);
        }
    };

    const isCheckedNode = (ancestor, node) => {
        if (ancestor) {
            const parentCategory = value?.find(item => item._id === ancestor._id)
            return parentCategory?.subCategories?.some(item => item._id === node._id) ?? false
        }
        return value?.some(item => item._id === node._id) ?? false
    }

    const renderTree = (nodes, ancestor) => (
        <FormGroup>
            {nodes?.map((node) => (
                <div key={node._id}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCheckedNode(ancestor, node)}
                                onChange={(e) => handleCheckboxChange(e, ancestor, node)}
                            />
                        }
                        label={node.name}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        {Array.isArray(node.subCategories) && node.subCategories.length > 0
                            ? renderTree(node.subCategories, node,)
                            : null}
                    </Box>
                </div>
            ))}
        </FormGroup>
    );

    return (<div>{renderTree(nodes, null)}</div>)
}



export default CheckboxTree;