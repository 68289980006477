import axios from "axios";

console.log(process.env.REACT_APP_ENV);
const axiosInstance = axios.create({
    baseURL:
        process.env.REACT_APP_ENV?.trimEnd() === "LOCAL"
            ? "https://juggle-backend-19aa95928c84.herokuapp.com/api/v1/"
            : "https://juggle-backend-19aa95928c84.herokuapp.com/api/v1/",
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers["authorization"] = "Bearer " + token; // for Spring Boot back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "api/v1/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401) {
                localStorage.removeItem("accessToken");
                window.location.reload();
            }
        }

        return Promise.reject(err);
    }
);
export default axiosInstance;
