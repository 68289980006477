import React from "react";
import { useGetMerchant } from "../api";
import { Avatar, Button } from "@mui/material";
import addToDatabase from "../../api/addToDatabase";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import useCategories from '../../../../views/stores/categories/hooks/useCategoriesQuery';
import CategorySelect from "../../../../components/ui/CategorySelect";

export default function AddToDb({ id }) {
    const { data, isPending, isError } = useGetMerchant(id);
    const { register, handleSubmit, control } = useForm();
    const { data: categories } = useCategories()

    const handleClick = async (body) => {
        const res = await addToDatabase({
            store_id: id,
            name: data.Name,
            commission_rate: body.reward_rate,
            title: body.title,
            source: "commission-factory",
            description: data.Summary,
            tracking_url: data.TrackingUrl,
            logo_url: data.AvatarUrl,
            url: data.TrackingUrl,
            categories: body.categories,
        });
        toast.success(res.message);
    };

    if (isPending) return <div>Loading...</div>;
    if (isError) return <div>Something went wrong</div>;
    return (
        <div>
            <div className=" max-w-screen-md mx-auto py-5 flex flex-col space-y-2">
                <div className="flex my-2 items-center gap-10">
                    <Avatar src={data.AvatarUrl} />
                    <h1 className="text-lg font-medium">{data.Name}</h1>
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Title: </h1>
                    <input
                        {...register("title")}
                        placeholder="Enter title"
                        className="w-2/3 py-2 px-2 rounded"
                    />
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Commisstion Rate: </h1>
                    <h1 className="w-2/3">{data.CommissionRate}</h1>
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Commisstion Type : </h1>
                    <h1 className="w-2/3">{data.CommissionType}</h1>
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Tracking Url: </h1>
                    <h1 className="w-2/3">{data.TrackingUrl}</h1>
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">commission Rate: </h1>
                    <input
                        {...register("commission_rate")}
                        className="w-2/3 py-2 rounded px-2"
                        type="text"
                        placeholder="Enter commission Rate"
                    />
                </div>
                <div className="flex items-center">
                    <h1 className="w-1/3">Categories : </h1>
                    <Controller
                        name="categories"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                            <CategorySelect
                                options={categories}
                                {...rest}
                            />
                        )}
                    />
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Target Market: </h1>
                    <h1 className="w-2/3">{data.TargetMarket}</h1>
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Category: </h1>
                    <h1 className="w-2/3">{data.Category}</h1>
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Description: </h1>
                    <h1 className="w-2/3">{data.Summary}</h1>
                </div>
                <div className="flex flex-col items-center w-full my-2">
                    <Button
                        onClick={handleSubmit(handleClick)}
                        className="bg-indigo-500 w-60"
                        variant="contained"
                    >
                        Add to Database
                    </Button>
                </div>
            </div>
        </div>
    );
}
