import React from "react";
import { map } from "lodash";
import { Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";

export default function PartnerizeCampains({ usePartnerizeCampaigns }) {
    const { data, isPending, isError } = usePartnerizeCampaigns;

    if (isPending) return <div>Loading...</div>;
    if (isError) return <div>Something went wrong</div>;
    return (
        <div className="grid grid-cols-3 gap-5 pt-10">
            {map(data.campaigns, (item) => {
                const { campaign } = item;
                return (
                    <Card key={campaign.campaign_id}>
                        <CardContent>
                            <div className="">
                                <Link
                                    to={`/stores/partnerize/${campaign.campaign_id}`}
                                >
                                    <h1>{campaign.title}</h1>
                                    <h1>{campaign.title}</h1>
                                </Link>

                                <p>{campaign.destination_url}</p>
                                <p>{campaign.description.en_us}</p>
                            </div>
                        </CardContent>
                    </Card>
                );
            })}
        </div>
    );
}
