import axiosInstance from "../../../config/Helpers";
import { useQuery } from "@tanstack/react-query";

const fetchRaku = async () => {
    const data = await axiosInstance.get("rakuten/merch");
    const allData = await axiosInstance.get("affiliate-program/rakuten/");
    return { data, allData };
};

export default function useRaku() {
    return useQuery({
        queryKey: ["raku"],
        queryFn: fetchRaku,
    });
}
