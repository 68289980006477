import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import React from "react";
import CategoryTable from "./components/CategoryTable";
import UpsertCategoryModal from "./components/UpsertCategoryModal";
import useCategoriesQuery from "./hooks/useCategoriesQuery";

const CategoriesMain = () => {
    const { data, isPending } = useCategoriesQuery();
    if (isPending) return <p>Loading..</p>

    return (
        <Grid container spacing={2} padding={4}>
            <div className="flex flex-row justify-between items-center mb-3 w-full">
                <Typography variant="h3" component="div">
                    Categories
                </Typography>
                <UpsertCategoryModal />
            </div>
            <CategoryTable data={data} />
        </Grid>
    );
};

export default CategoriesMain;
