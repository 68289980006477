import React from "react";
import { useParams } from "react-router";
import { Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import useOffer from "./api/use-offer";
import useCategories from "../../../views/stores/categories/hooks/useCategoriesQuery";
import CategorySelect from "../../../components/ui/CategorySelect";
import addToDatabase from "../impact/api/addToDatabase";
export default function OfferDetails() {
    const { id } = useParams();
    const { data: categories } = useCategories();
    const { data, isPending, isError } = useOffer(id);
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = useForm({});
    const onSubmit = async (formData) => {
        const response = await addToDatabase({
            ...formData,
            logo_url: data.thumbnail_url,
            tracking_url: data.tracking_url,
            name: data.name,
            source: "everflow",
            store_id: data.network_offer_id,
        });
        if (response) {
            toast.success("Added to Database");
        }
    };
    if (isPending) return <div>Loading...</div>;
    if (isError) return <div>Error</div>;
    return (
        <div className="max-w-screen-md mx-auto bg-white shadow-md p-5 rounded-md">
            <form className="flex flex-col gap-3">
                <div className="">
                    <img src={data?.thumbnail_url} alt={data?.name} />
                </div>
                <div className="grid grid-cols-3">
                    <h1>Id</h1>
                    <h1>{data?.network_offer_id}</h1>
                </div>

                <div className="flex ">
                    <h1 className="w-1/3">Name </h1>
                    <input
                        disabled
                        defaultValue={data?.name || ""}
                        className="w-2/3 py-2 px-2 rounded border-2 border-gray-300"
                    />
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Title: </h1>
                    <input
                        {...register("title")}
                        placeholder="Enter title"
                        className="w-2/3 py-2 px-2 rounded border-2 border-gray-300"
                    />
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Commission Rate: </h1>
                    <input
                        {...register("commission_rate")}
                        className="w-2/3 py-2 rounded px-2 border-2 border-gray-300"
                        type="text"
                        placeholder="Enter commission Rate"
                    />
                </div>

                <div className="flex ">
                    <h1 className="w-1/3">Points</h1>
                    <input
                        {...register("points")}
                        className="w-2/3 py-2 rounded px-2 border-2 border-gray-300"
                        type="text"
                        placeholder="Enter commission Rate"
                    />
                </div>
                <div className="flex ">
                    <h1 className="w-1/3">Tracking Url </h1>
                    <input
                        disabled
                        defaultValue={data?.tracking_url || ""}
                        className="w-2/3 py-2 px-2 rounded border-2 border-gray-300"
                    />
                </div>
                <div className="flex">
                    <h1 className="w-1/3">Category</h1>
                    <h1>{data?.relationship.category.name}</h1>
                </div>

                <div className="flex items-center">
                    <h1 className="w-1/3">Select Categories </h1>
                    <Controller
                        name="categories"
                        control={control}
                        render={({ field: { ref, ...rest } }) => (
                            <CategorySelect options={categories} {...rest} />
                        )}
                    />
                </div>
                <div className="">
                    <h1 className="font-semibold ">Given Discription</h1>
                    <div
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: data?.html_description,
                        }}
                    />
                </div>
                <div className="flex ">
                    <h1 className="font-semibold w-1/3 "> Discription</h1>
                    <textarea
                        className="w-2/3 py-2 px-2 rounded border-2 border-gray-300"
                        {...register("description")}
                    />
                </div>
                <div className="flex flex-col items-center w-full my-2">
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        className="bg-indigo-500 w-60"
                        variant="contained"
                    >
                        Add to Database
                    </Button>
                </div>
            </form>
        </div>
    );
}
