import React from "react";
import { Link } from "react-router-dom";

export default function Everflow({ data, isPending, isError }) {
    return (
        <div>
            {isPending && <div>Loading...</div>}
            {isError && <div>Error</div>}
            {!isPending && !isError && !data && <div>No data available</div>}
            {data && (
                <div className="py-10 container">
                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4  gap-5 ">
                        {data.map((offer) => (
                            <div
                                className="bg-white rounded-md shadow-md"
                                key={offer.network_offer_id}
                            >
                                <Link
                                    to={`/stores/everflow/${offer.network_offer_id}`}
                                >
                                    <img
                                        src={offer.thumbnail_url}
                                        className="h-60 w-full rounded-t-md "
                                    />
                                </Link>
                                <div className="p-2 bg-green-300 text-black rounded-b-md ">
                                    <div className=" bg-white p-1 rounded-3xl w-20 text-center capitalize mb-2">
                                        {
                                            offer.relationship
                                                .offer_affiliate_status
                                        }
                                    </div>
                                    <h2 className="text-xl">{offer.name}</h2>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
