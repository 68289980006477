import axiosInstance from "../../../../config/Helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const updateCategories = async (payload) => {
    const res = await axiosInstance.patch(`upsertCategory`, payload);
    return res.data;
};


const useCategoryMutation = () => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: updateCategories,
        onSuccess: () => {
            console.log(queryClient.getQueryCache().getAll());
            queryClient.invalidateQueries({ queryKey: ['categories'] });
        },
    });
    return mutation
}

export default useCategoryMutation;