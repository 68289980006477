import { useQuery } from "@tanstack/react-query";

import axiosInstance from "../../../../config/Helpers";

const url = "affiliate-program/comfactory/all";
export default function useGetBanners() {
    return useQuery({
        queryKey: ["commission-factory"],
        queryFn: async () => {
            const response = await axiosInstance.get(url);
            return response.data;
        },
    });
}
