import React from "react";
import { Dialog, Button } from "@mui/material";

export default function ConfirmationDialog({ open, handleClose }) {
    return (
        <Dialog open={open} handleClose={handleClose}>
            <div className="p-5">
                <h1>Are you sure?</h1>
                <div className="flex gap-5 mt-2">
                    <Button variant="contained" className="bg-red-500">
                        Yes
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="bg-gray-200"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
