import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../../config/Helpers";
export default function useSliceOffers() {
    return useQuery({
        queryKey: ["sliceOffers"],
        queryFn: async () => {
            const { data } = await axiosInstance.get(
                "affiliate-program/slice/offers"
            );
            return data;
        },
    });
}
