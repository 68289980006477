import React from "react";
import { Tabs, Tab } from "@mui/material";
import RukuMerchantList from "./components/raku-merch";
import ImpactStores from "./components/impact-stores";
import { CommissionFactory } from "./commission-factory/components";
import { useGetBanners } from "./commission-factory/api";
import PartnerizeCampains from "./components/partnerize-campains";
import useStoreCount from "./api/useStoreCount";
import useGetCampaigns from "./impact/api/useGetCampaigns";
import useRaku from "./api/useRaku";
import usePartnerizeCampaigns from "./api/useGetPartnerizeCampaigns";
import Everflow from "./everflow/everflow";
import useAllOffers from "./everflow/api/use-alloffers";
import { SliceOffers } from "./slice";
import { useSliceOffers } from "./slice/api";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
export default function AffiliatePartners() {
    const { data, isPending, isError } = useStoreCount();
    const everflow = useAllOffers();

    const impactCampaigns = useGetCampaigns();
    const rakuStores = useRaku();
    const partnerizeStores = usePartnerizeCampaigns();
    const commissionFactory = useGetBanners();
    const slice = useSliceOffers();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (isPending) return <div>Loading...</div>;
    if (isError) return <div>Error...</div>;

    const counts = {
        rakuten: data.data.find((d) => d._id === "rakuten") || { count: 0 },
        impact: data.data.find((d) => d._id === "impact") || { count: 0 },
        partnerize: data.data.find((d) => d._id === "partnerize") || {
            count: 0,
        },
        comfact: data.data.find((d) => d._id === "commission-factory") || {
            count: 0,
        },
        rakutenAPI: !rakuStores.isLoading
            ? rakuStores.data.data.data["ns1:getMerchByAppStatusResponse"][
                  "ns1:return"
              ].length
            : 0,
        impactAPI: !impactCampaigns.isLoading
            ? impactCampaigns.data.Campaigns.length
            : 0,
        partnerizeAPI: !partnerizeStores.isLoading
            ? partnerizeStores.data.count
            : 0,

        comfactAPI: !commissionFactory.isPending
            ? commissionFactory.data && commissionFactory.data.length
            : 0,
    };
    return (
        <main>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="affiliate partners"
            >
                <Tab
                    label={`Rakuten (DB: ${counts.rakuten.count}) (API: ${counts.rakutenAPI})`}
                    {...a11yProps(0)}
                />
                <Tab
                    label={`Impact.com (DB: ${counts.impact.count}) (API: ${counts.impactAPI})`}
                    {...a11yProps(1)}
                ></Tab>
                <Tab
                    label={`Partnerize (DB: ${counts.partnerize.count}) (API: ${counts.partnerizeAPI})`}
                    {...a11yProps(2)}
                ></Tab>
                <Tab
                    label={`Commission Factory (DB: ${counts.comfact.count} ) (API: ${counts.comfactAPI})`}
                    {...a11yProps(3)}
                />
                <Tab label={`Everflow`} {...a11yProps(4)} />
                <Tab label={`SLICE`} {...a11yProps(5)} />
            </Tabs>

            {value === 0 && <RukuMerchantList useRaku={rakuStores} />}
            {value === 1 && (
                <ImpactStores
                    data={impactCampaigns.data}
                    isPending={impactCampaigns.isPending}
                    isError={impactCampaigns.isError}
                />
            )}
            {value === 2 && (
                <PartnerizeCampains usePartnerizeCampaigns={partnerizeStores} />
            )}
            {value === 3 && (
                <CommissionFactory
                    isPending={commissionFactory.isPending}
                    data={commissionFactory.data}
                    isError={commissionFactory.isError}
                />
            )}
            {value === 4 && (
                <Everflow
                    data={everflow.data.offers}
                    isPending={everflow.isPending}
                    isError={everflow.isError}
                />
            )}
            {value === 5 && (
                <SliceOffers
                    data={slice.data}
                    isPending={slice.isPending}
                    isError={slice.isError}
                />
            )}
        </main>
    );
}
