import axiosInstance from "../../../../config/Helpers";
import { useQuery } from "@tanstack/react-query";

const getCategories = async () => {
    const res = await axiosInstance.get(`getCategories`);
    return res.data;
};

const useCategories = () => {
    return useQuery({
        queryKey: ['categories'],
        queryFn: () => getCategories(),
    });
}

export default useCategories;