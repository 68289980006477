export const storeTypeOptions = [
    { value: "online", label: "Online" },
    { value: "instore", label: "In Store" },
    { value: "both", label: "Both" },
];

export const storeCategoriesOptions = [
    { value: "electronics", label: "Electronics" },
    { value: "jewelery", label: "Jewelery" },
    { value: "men's clothing", label: "Men's Clothing" },
    { value: "women's clothing", label: "Women's Clothing" },
];
